import React, { createContext, useState, useEffect } from 'react';
import { fetchData, mUser } from '../config/Config';
import Notification, { notifySuccess, notifyError } from '../config/Notification'
// Membuat context
export const AuthContext = createContext();

// Membuat provider untuk context
export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    mToken: localStorage.getItem('mToken'),
    mUser: JSON.parse(localStorage.getItem('mUser')),
  });
  const [isLoggedOut, setIsLoggedOut] = useState(0);

  useEffect(() => {
    const mToken = localStorage.getItem('mToken');
    const mUser = JSON.parse(localStorage.getItem('mUser'));

    if (mToken && mUser) {
      setAuth({ mToken, mUser });
    }
    
  }, []);

  const login = (mToken, mUser) => {
    localStorage.setItem('mToken', mToken);
    localStorage.setItem('mUser', JSON.stringify(mUser));
    setAuth({ mToken, mUser });
    return true;
  };

  const logout = async () => {
    try {
      const data = await fetchData(`logout`,{
        method : 'POST',
        credentials: 'include',
        headers : {
          'Content-Type' : 'application/json',
          'Accept' : 'application/json'
        },
        body : JSON.stringify({username : mUser.username})
      });

      if(data.success){
        localStorage.removeItem('mToken');
        localStorage.removeItem('mUser');
        setAuth({ mToken: null, mUser: null }); 
        setIsLoggedOut(1)
      }else{
        notifyError(data.message)
      }
    } catch (error) {
      console.error('Logout error:', error);
      notifyError(error.message)
    }
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
