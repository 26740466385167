import React, { useEffect, useState } from 'react'
import MemberHistoriesStore from '../../../store/MemberHistoriesStore';
import { mUser, mToken, fetchData } from '../../../config/Config';
import Notification, { notifySuccess, notifyError } from '../../../config/Notification'
import MemberCoursesStore from '../../../store/MemberCoursesStore';

const LessonCollapse = ({ lessonNow, lesson, handleLessonClick, dataRequired}) => {
  const { history, fetchHistory } = MemberHistoriesStore();
  const { courses, fetchCourses, loadingScreen } = MemberCoursesStore();
  const isChecked = Array.isArray(history) && history.some(l => l.lesson_id == lesson.id);

  const handleCheckboxChange = (lessonId) => {
    if (lessonId) {
      addDeleteProgress({
        username: mUser.username,
        lesson_id: lessonId
      })
    }

  }

  const addDeleteProgress = async (formData) => {
    try {
      const data = await fetchData(`progress/add-destroy`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${mToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (data.success) {
        fetchHistory()
        fetchCourses()
      }
    } catch (error) {
      notifyError(error.message)
    }
  }


  return (
    lesson.required == 1 ?
    dataRequired ? <button key={lesson.id} className={`flex items-center justify-between rounded-lg  py-4 md:px-4  group  flex-col gap-2 md:flex-row ${lessonNow && lessonNow.id == lesson.id ? 'bg-sky-400 text-white' : 'hover:bg-slate-200 bg-slate-50'}`} disabled>
      <div className="flex gap-2 items-center w-60 md:w-52 lg:w-64">
        <i className="fa-solid fa-lock text-yellow-400" />
        <p className="text-ellipsis overflow-hidden  whitespace-nowrap">{lesson.lesson_name}</p>
      </div>
      <div className="flex gap-2 items-center w-full md:w-32 justify-between md:justify-end flex-1 px-4 md:px-0">
        <p className="text-sm text-slate-500 ">{lesson.duration}</p>
        
      </div>
    </button> : <button key={lesson.id} onClick={() => handleLessonClick(lesson.id)} className={`flex items-center justify-between rounded-lg  py-4 md:px-4  group  flex-col gap-2 md:flex-row ${lessonNow && lessonNow.id == lesson.id ? 'bg-sky-400 text-white' : 'hover:bg-slate-200 bg-slate-50'}`}>
      <div className="flex gap-2 items-center w-60 md:w-52 lg:w-64">
        <i className="fa-solid fa-play-circle" />
        <p className="text-ellipsis overflow-hidden  whitespace-nowrap">{lesson.lesson_name}</p>
      </div>
      <div className="flex gap-2 items-center w-full md:w-32 justify-between md:justify-end flex-1 px-4 md:px-0">
        <p className="text-sm text-slate-500 ">{lesson.duration}</p>
        <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer" type="checkbox" title='Check jika memahami materi'
          onChange={() => handleCheckboxChange(lesson.id)}
          checked={isChecked} />
      </div>
    </button> 
    
    : <button key={lesson.id} onClick={() => handleLessonClick(lesson.id)} className={`flex items-center justify-between rounded-lg  py-4 md:px-4  group  flex-col gap-2 md:flex-row ${lessonNow && lessonNow.id == lesson.id ? 'bg-sky-400 text-white' : 'hover:bg-slate-200 bg-slate-50'}`}>
      <div className="flex gap-2 items-center w-60 md:w-52 lg:w-64">
        <i className="fa-solid fa-play-circle" />
        <p className="text-ellipsis overflow-hidden  whitespace-nowrap">{lesson.lesson_name}</p>
      </div>
      <div className="flex gap-2 items-center w-full md:w-32 justify-between md:justify-end flex-1 px-4 md:px-0">
        <p className="text-sm text-slate-500 ">{lesson.duration}</p>
        <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer" type="checkbox" title='Check jika memahami materi'
          onChange={() => handleCheckboxChange(lesson.id)}
          checked={isChecked} />
      </div>
    </button>
    

    
  )
    
}

export default LessonCollapse