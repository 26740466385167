import React from 'react'
import { Helmet } from 'react-helmet'
import {BASE_URL} from '../config/Config'

const Product = () => {
  return (
    <div>
      <Helmet>
        <title>Product | Coding Asik Academy</title>
      </Helmet>
      <div className="text-center mt-8 mb-16">
        <h3 className="font-semibold text-2xl mb-2">Courses</h3>
        <p>apa yang kamu ingin pelajari?</p>
      </div>
      <div className="mt-16 text-center mb-24">
        <div className="flex flex-wrap justify-evenly gap-12 lg:gap-10 p-4 lg:p-0">
          <a className="bg-yellow-50 shadow-md p-8 w-full lg:w-72 rounded-md hover:scale-105 hover:bg-yellow-100 transition-all ease-in-out relative group " href={`${BASE_URL}/course/jago-php`}>
            <span className="bg-yellow-400 text-xs px-2 py-1 rounded-lg absolute top-0 right-0 text-white">Terbaru</span>
            <h2 className="font-bold">Jago PHP</h2>
            <div className="my-4 w-full flex justify-center items-center ">
              <img src={`${BASE_URL}/public/img/cover/course/cover_course_jago_php.png`} alt="Course Jago PHP" />
            </div>
            <p className="text-sm text-left overflow-hidden">
              Di course PHP kamu akan belajar membuat sistem basis web dengan belajar konsep tipe data, perulangan, koneksi database, oop dll
            </p>
            <div className="flex justify-start mt-4">
              <span className="bg-sky-400 text-white text-sm py-1 px-2 rounded-full">lanjut</span>
            </div>
            <div className="pointer-events-none opacity-0 transition-opacity group-hover:opacity-100 w-full bg-sky-400 text-white py-1 absolute bottom-0 left-0 -mb-6 rounded-br-md rounded-bl-md z-30 text-sm">Selengkapnya</div>
          </a>
          <a className="bg-yellow-50 shadow-md p-8 w-full lg:w-72 rounded-md hover:scale-105 hover:bg-yellow-100 transition-all ease-in-out relative group " href={`${BASE_URL}/course/jago-web-design`}>
            <span className="bg-yellow-400 text-xs px-2 py-1 rounded-lg absolute top-0 right-0 text-white">Rekomendasi</span>
            <h2 className="font-bold">Jago Web Design (JWD)</h2>
            <div className="my-4">
              <img src={`${BASE_URL}/public/img/cover/course/jago_web_design.webp`} alt="Jago Web Design" />
            </div>
            <p className="text-sm text-left overflow-hidden">
              Buat kamu yang ingin belajar HTML CSS dan Javascript dalam 1 Course dengan belajar konsep web design dari 0.
            </p>
            <div className="flex justify-start mt-4">
              <span className="bg-green-400 text-white text-sm py-1 px-2 rounded-full">pemula</span>
            </div>
            <div className="pointer-events-none opacity-0 transition-opacity group-hover:opacity-100 w-full bg-sky-400 text-white py-1 absolute bottom-0 left-0 -mb-6 rounded-br-md rounded-bl-md z-30 text-sm">Selengkapnya</div>
          </a>
          <a className="shadow-md p-8 w-full lg:w-72 rounded-md hover:scale-105 hover:bg-slate-100 transition-all ease-in-out relative group " href={`${BASE_URL}/series/rahasia-web-programming`}>
            <h2 className="font-bold">Rahasia Web Programming</h2>
            <div className="my-4 w-full flex justify-center items-center ">
              <img className="w-40" src={`${BASE_URL}/public/img/cover/course/cover_traktir_kopi_rahasia_web_programming.webp`} alt="Rahasia Web Programming" />
            </div>
            <p className="text-sm text-left overflow-hidden ">
              Mau tau semua rahasia Web Programming mulai dari cara belajar sampai dengan cara Mmnghasilkan uangnya? Semua dijelaskan disini
            </p>
            <div className="flex justify-start mt-4">
              <span className="bg-green-400 text-white text-sm py-1 px-2 rounded-full">pemula</span>
            </div>
            <div className="pointer-events-none opacity-0 transition-opacity group-hover:opacity-100 w-full bg-sky-400 text-white py-1 absolute bottom-0 left-0 -mb-6 rounded-br-md rounded-bl-md z-30 text-sm">Selengkapnya</div>
          </a>
          <a className="shadow-md p-8 w-full lg:w-72 rounded-md hover:scale-105 hover:bg-slate-100 transition-all ease-in-out relative group " href={`${BASE_URL}/course/jago-html`}>
            <h2 className="font-bold">Jago HTML (CJH)</h2>
            <div className="my-4">
              <img src={`${BASE_URL}/public/img/cover/course/jago_html.webp`} alt="Jago HTML" />
            </div>
            <p className="text-sm text-left overflow-hidden ">
              Materi wajib yang harus kamu pelajari saat kamu baru masuk ke web programming, dimana fokus mengenal tag dan element di web.
            </p>
            <div className="flex justify-start mt-4">
              <span className="bg-green-400 text-white text-sm py-1 px-2 rounded-full">pemula</span>
            </div>
            <div className="pointer-events-none opacity-0 transition-opacity group-hover:opacity-100 w-full bg-sky-400 text-white py-1 absolute bottom-0 left-0 -mb-6 rounded-br-md rounded-bl-md z-30 text-sm">Selengkapnya</div>
          </a>
          <a className="shadow-md p-8 w-full lg:w-72 rounded-md hover:scale-105 hover:bg-slate-100 transition-all ease-in-out relative group " href={`${BASE_URL}/course/jago-css`}>
            <h2 className="font-bold">Jago CSS (CJC)</h2>
            <div className="my-4">
              <img src={`${BASE_URL}/public/img/cover/course/jago_css.webp`} alt />
            </div>
            <p className="text-sm text-left overflow-hidden ">
              Materi setelah kamu belajar HTML dimana kamu fokus styling web mulai dari coloring, layouting, responsive dll di web.
            </p>
            <div className="flex justify-start mt-4">
              <span className="bg-green-400 text-white text-sm py-1 px-2 rounded-full">pemula</span>
            </div>
            <div className="pointer-events-none opacity-0 transition-opacity group-hover:opacity-100 w-full bg-sky-400 text-white py-1 absolute bottom-0 left-0 -mb-6 rounded-br-md rounded-bl-md z-30 text-sm">Selengkapnya</div>
          </a>
          <a className="shadow-md p-8 w-full lg:w-72 rounded-md hover:scale-105 hover:bg-slate-100 transition-all ease-in-out relative group " href={`${BASE_URL}/course/jago-javascript`}>
            <h2 className="font-bold">Jago Javascript (CJJ)</h2>
            <div className="my-4">
              <img src={`${BASE_URL}/public/img/cover/course/jago_javascript.webp`} alt="Jago Javascript" />
            </div>
            <p className="text-sm text-left overflow-hidden ">
              Materi setelah kamu belajar HTML &amp; CSS. Fokus belajar membuat web interaktif, mengolah data di web. Cocok untuk pemula dan pro.
            </p>
            <div className="flex justify-start mt-4">
              <span className="bg-green-400 text-white text-sm py-1 px-2 rounded-full">pemula</span>
            </div>
            <div className="pointer-events-none opacity-0 transition-opacity group-hover:opacity-100 w-full bg-sky-400 text-white py-1 absolute bottom-0 left-0 -mb-6 rounded-br-md rounded-bl-md z-30 text-sm">Selengkapnya</div>
          </a>
          <a className="shadow-md p-8 w-full lg:w-72 rounded-md hover:scale-105 hover:bg-slate-100 transition-all ease-in-out relative group " href={`${BASE_URL}/course/jago-database-mysql`}>
            <h2 className="font-bold">Jago Database Mysql (JDM)</h2>
            <div className="my-4">
              <img src={`${BASE_URL}/public/img/cover/course/jago_database_mysql.png`} alt="Jago Database Mysql" />
            </div>
            <p className="text-sm text-left overflow-hidden ">
              Setelah kuasai basic web dan ingin fokus ke Backend, materi ini wajib kamu pelajari. Disini fokus belajar olah data dengan database Mysql
            </p>
            <div className="flex justify-start mt-4">
              <span className="bg-sky-400 text-white text-sm py-1 px-2 rounded-full">menengah</span>
            </div>
            <div className="pointer-events-none opacity-0 transition-opacity group-hover:opacity-100 w-full bg-sky-400 text-white py-1 absolute bottom-0 left-0 -mb-6 rounded-br-md rounded-bl-md z-30 text-sm">Selengkapnya</div>
          </a>
        </div>
      </div>
    </div>

  )
}

export default Product