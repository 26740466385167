import React, { useEffect, useState } from 'react'
import CourseReviewsStore from '../store/CourseReviewsStore'
import CourseListsStore from '../store/CourseListsStore'
import CourseRatingsStore from '../store/CourseRatingsStore'
import Pagination from '../components/Pagination'
import {calculateTimeDifference} from '../config/Config'
import Loading from '../components/Loading'
import { Helmet } from 'react-helmet'
const Review = () => {
  const { reviews,currentPage,itemsPerPage,totalPages,totalData,from,to,lastPage,setPage, fetchReviews,loadingScreen } = CourseReviewsStore()

  const { lists, fetchLists } = CourseListsStore()

  const { ratings, fetchRatings } = CourseRatingsStore()
  const [filter , setFilter] = useState('')

  useEffect(() => {
    fetchLists()
  }, [])

  useEffect(() => {
      fetchRatings(filter);
  }, [filter, fetchRatings]); 

  useEffect(() => {
    fetchReviews(currentPage,itemsPerPage,filter);
  }, [currentPage, filter])


  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  return (
    <div>
      <Helmet>
        <title>Review | Coding Asik Academy</title>
      </Helmet>
      {!loadingScreen && <Loading/>}
      <h3 className="font-semibold text-2xl text-center mt-8 ">Review Member</h3>
      <input type="hidden" id="courseId" defaultValue="all" />
      <div className="mb-16 mt-8 w-full flex justify-center">
        <div className="w-fit flex gap-4 bg-slate-200 p-4 rounded-lg mx-4">
          <select className="px-4 py-2 rounded-md outline-none focus:border-sky-400" name="sort" value={filter} onChange={e => setFilter(e.target.value)}>
            <option value="">All</option>
            {lists.map(list => (
              <option key={list.id} value={list.id}>{list.course_name}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="lg:flex lg:gap-8 lg:mt-12 lg:items-start">
        <div className="mt-10 lg:w-1/2 lg:bg-slate-50 lg:mt-0 lg:p-8 lg:rounded-lg">
          <div className>
            <div className="w-full text-center flex justify-center">
              <div className="p-4 rounded-full bg-slate-200 flex-col justify-center w-full items-center">
                <div className="flex justify-center gap-2">
                  <i className="fa-solid fa-star text-yellow-400" />
                  <i className="fa-solid fa-star text-yellow-400" />
                  <i className="fa-solid fa-star text-yellow-400" />
                  <i className="fa-solid fa-star text-yellow-400" />
                  <i className="fa-solid fa-star text-yellow-400" />
                </div>
                <p className="mt-2">{ ((ratings.rating5/ratings.total_reviews)*5).toFixed(1)} out of 5</p>
              </div>
            </div>
            <p className="text-center mt-4 mb-8 text-sm text-slate-500">{ratings.total_reviews} review member from {ratings.total_members} member</p>
            <div className="flex flex-col gap-4">
              <div className="w-full flex items-center gap-2">
                <p className="w-16">5 star</p>
                <div className="h-3 w-full bg-slate-200 rounded-lg relative">
                  <div className="bg-yellow-400 absolute top-0 left-0 h-3 rounded-lg" style={{ width: Math.round((ratings.rating5/ratings.total_reviews)*100)+'%' }} />
                </div>
                <p className="w-12">{Math.round((ratings.rating5/ratings.total_reviews)*100)+'%'}</p>
              </div>
              <div className="w-full flex items-center gap-2">
                <p className="w-16">4 star</p>
                <div className="h-3 w-full bg-slate-200 rounded-lg relative">
                  <div className="bg-yellow-400 absolute top-0 left-0 h-3 rounded-lg" style={{ width: Math.round((ratings.rating4/ratings.total_reviews)*100)+'%' }} />
                </div>
                <p className="w-12">{Math.round((ratings.rating4/ratings.total_reviews)*100)+'%'}</p>
              </div>
              <div className="w-full flex items-center gap-2">
                <p className="w-16">3 star</p>
                <div className="h-3 w-full bg-slate-200 rounded-lg relative">
                  <div className="bg-yellow-400 absolute top-0 left-0 h-3 rounded-lg" style={{ width: Math.round((ratings.rating3/ratings.total_reviews)*100)+'%' }} />
                </div>
                <p className="w-12">{Math.round((ratings.rating3/ratings.total_reviews)*100)+'%'}</p>
              </div>
              <div className="w-full flex items-center gap-2">
                <p className="w-16">2 star</p>
                <div className="h-3 w-full bg-slate-200 rounded-lg relative">
                  <div className="bg-yellow-400 absolute top-0 left-0 h-3 rounded-lg" style={{ width: Math.round((ratings.rating2/ratings.total_reviews)*100)+'%' }} />
                </div>
                <p className="w-12">{Math.round((ratings.rating2/ratings.total_reviews)*100)+'%'}</p>
              </div>
              <div className="w-full flex items-center gap-2">
                <p className="w-16">1 star</p>
                <div className="h-3 w-full bg-slate-200 rounded-lg relative">
                  <div className="bg-yellow-400 absolute top-0 left-0 h-3 rounded-lg" style={{ width: Math.round((ratings.rating1/ratings.total_reviews)*100)+'%' }} />
                </div>
                <p className="w-12">{Math.round((ratings.rating1/ratings.total_reviews)*100)+'%'}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:flex lg:gap-8 lg:flex-col lg:w-1/2">
          <div className="mt-12 lg:w-full lg:mt-0">
            <div className>
              <div className="flex flex-col gap-4">
                { 
                  reviews == ''? <div className="w-full flex gap-8">
                  <div className="animate-pulse flex space-x-4 w-1/2">
                    <div className="flex-1 space-y-6 py-1">
                      <div className="h-2 bg-slate-200 rounded" />
                      <div className="h-2 bg-slate-200 rounded" />
                      <div className="h-2 bg-slate-200 rounded" />
                    </div>
                  </div>
                  <div className="animate-pulse flex space-x-4 w-1/2">
                    <div className="flex-1 space-y-6 py-1">
                      <div className="h-2 bg-slate-200 rounded" />
                      <div className="h-2 bg-slate-200 rounded" />
                      <div className="h-2 bg-slate-200 rounded" />
                    </div>
                  </div>
                </div> :
                  reviews.map(review=>(
                  <div key={review.id} className="bg-slate-50 p-4 rounded-lg">
                    <div className="flex items-center gap-4">
                      <img className="w-12 h-12 object-cover rounded-full" src={review.picture} alt={`${review.first_name} ${review.last_name}`} />
                      <div className="w-full">
                        <h4 className="font-semibold capitalize">{review.first_name} {review.last_name}</h4>
                        <div className="flex gap-2 w-full mt-2 items-center justify-between flex-wrap">
                          <div className="flex gap-2 items-center">
                            <div className="flex w-50">
                              {Array.from({ length: review.rating }, (_, index) => (
                                <i key={index} className="fa-solid fa-star text-yellow-400" />
                              ))}
                            </div>
                            <h4 className="font-semibold">{review.rating}</h4>
                          </div>
                          <p className="text-slate-500 text-sm"> {calculateTimeDifference(review.review_at)}</p>
                        </div>
                      </div>
                    </div>
                    <p className="w-full mt-4 text-slate-500">
                      {review.review}
                    </p>
                    <div className="mt-2 rounded-full py-1 px-4 text-xs bg-green-200 inline-block">{review.course_name}
                    </div>
                  </div>
                ))}

              </div>
            </div>
            <div className="mt-6 mb-4 flex flex-col gap-4 items-center justify-between px-4">
              <p>Show Data : <span id="showData">{from} to {to} of {totalData} Data</span></p>
              <Pagination
                currentPage={currentPage}
                lastPage={lastPage}
                totalPages={totalPages} 
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Review