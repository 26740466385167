import React, { useState } from 'react'
import logoFull from '../assets/img/brand/logo-full.png'
import Notification, { notifySuccess, notifyError } from '../config/Notification'
import { Link, useNavigate } from 'react-router-dom';
import { fetchData } from '../config/Config';
const ResetPassword = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    code: '',
    password: '',
    confPassword: ''
  })
  const [loading, setLoading] = useState(0)

  const handleSubmit = (e) => {
    e.preventDefault()
    if (formData.email == '' || formData.code == '' || formData.password == '' || formData.confPassword == '') {
      notifyError('Pastikan semua data terinput');
    } else {
      if (formData.password != formData.confPassword) {
        notifyError('Pastikan konfirmasi password sama');
      } else {
        setLoading(1)
        fetchResetPassword()
      }
    }
  }

  const fetchResetPassword = async () => {
    try {
      const data = await fetchData(`reset-password`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      data.success == true ? notifySuccess(data.message) : notifyError(data.message);
      setLoading(0)
      setTimeout(() => {
        navigate('/login')
      }, 3000);
    } catch (error) {
      setLoading(0)
      notifyError(error.message)
    }
  }

  return (
    <main id="pageResetPassword">
      <div className='h-screen bg-cover bg-center -z-10 fixed w-full' style={{ backgroundImage: `url(${'https://images.unsplash.com/photo-1517134191118-9d595e4c8c2b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'})` }}>

      </div>
      <div className="p-4 ">
        <div className="w-full md:w-[26rem] mx-auto px-4 py-8 border border-slate-200 rounded-lg bg-slate-100 my-16 lg:my-32 bg-opacity-85">
          <div className="w-full flex justify-center flex-col items-center gap-6">
            <Link to="/login">
              <img className="w-40" src={logoFull} alt="Coding Asik" />
            </Link>
            <h3 className="text-xl text-slate-500 font-semibold">Reset Password</h3>
            <p className="text-slate-400 text-sm -mt-4">Inputkan token yang telah kami kirimkan di email</p>
          </div>
          <hr className="mt-8 mb-4" />
          <div className="mt-8 ">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2 w-full">
                  <label className="text-slate-500" htmlFor="email">Email</label>
                  <input value={formData.email} onChange={e => setFormData({
                    ...formData,
                    email: e.target.value
                  })} className="py-2 px-4 rounded-lg focus:border-2 focus:border-sky-400 outline-none border-2 boder-slate-50" type="email" name="email" id="email" placeholder="Inputkan email" autofocus />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <label className="text-slate-500" htmlFor="email">Kode Verifikasi</label>
                  <input value={formData.code} onChange={e => setFormData({
                    ...formData,
                    code: e.target.value
                  })} className="py-2 px-4 rounded-lg focus:border-2 focus:border-sky-400 outline-none border-2 boder-slate-50" type="text" name="verificationCode" id="verificationCode" />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <label className="text-slate-500" htmlFor="email">Password Baru</label>
                  <input value={formData.password} onChange={e => setFormData({
                    ...formData,
                    password: e.target.value
                  })} className="py-2 px-4 rounded-lg focus:border-2 focus:border-sky-400 outline-none border-2 boder-slate-50" type="password" name="newPassword" id="newPassword" placeholder="********" />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <label className="text-slate-500" htmlFor="email">Konfirmasi Password</label>
                  <input value={formData.confPassword} onChange={e => setFormData({
                    ...formData,
                    confPassword: e.target.value
                  })} className="py-2 px-4 rounded-lg focus:border-2 focus:border-sky-400 outline-none border-2 boder-slate-50" type="password" name="confirmPassword" id="confirmPassword" placeholder="********" />
                </div>
              </div>
              <div className="w-full mt-6">
                <button type="submit" className="w-full bg-sky-400 py-2 px-3 rounded-lg text-white hover:bg-sky-500 ">{!loading ? 'Reset Password' : 'Proses reset...'}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>


  )
}

export default ResetPassword