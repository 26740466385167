import React, { useState } from 'react'
import { mUser, mToken, fetchData } from '../../../config/Config'
import Notification,{ notifySuccess, notifyError }  from '../../../config/Notification'
const ModalPassword = ({showModalPassword, setShowModalPassword}) => {

  const [formData, setFormData] = useState({
    'passOld' : '',
    'passNew' : '',
    'verPass' : ''
  })
  const [loading, setLoading] = useState(0)
  const handleSubmit=e=>{
    e.preventDefault()
    setLoading(1)
    if(formData.passOld == '' || formData.passNew == '' || formData.verPass == ''){
      notifyError('Data tidak boleh kosong')
      setLoading(0)
    }else{
      fetchResetPassword()
    }
  }

  const fetchResetPassword = async()=>{
    try {
      const data = await fetchData(`member/${mUser.username}/reset-password`,{
        method: 'POST',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${mToken}`,
          'Content-Type' : 'application/json'
        },
        body : JSON.stringify(formData)
      });
      if(data.success == true){
        notifySuccess(data.message)
        setFormData({
          'passOld' : '',
          'passNew' : '',
          'verPass' : ''
        })
        setShowModalPassword(0)
      }else{
        notifyError(data.message);
      } 
      setLoading(0)
    } catch (error) {
      notifyError(error.message)
      setLoading(0)
    }
  }
  return (
    <div className={`${!showModalPassword && 'hidden'}  fixed top-0 left-0 w-full h-screen z-40 bg-black bg-opacity-25 flex justify-center items-center p-4`} >
      <div className="relative w-full md:w-3/6 ">
        <form onSubmit={handleSubmit} className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
            <h5 className="text-xl font-medium leading-normal text-gray-800" id="exampleModalCenteredScrollableLabel">
              Ubah password
            </h5>
            <button onClick={() => setShowModalPassword(0)} type="button" className="absolute top-0 right-0 w-8 h-8 flex justify-center items-center bg-slate-100 text-red-500 rounded-md shadow-md"><i className="fa-solid fa-close" /></button>
          </div>
          <div className="modal-body relative p-4">
            <div className="flex gap-4 flex-col">
              <div className="flex flex-col gap-2 w-full">
                <label className="text-slate-500" htmlFor="passOld">Password sekarang</label>
                <input onChange={e=>setFormData({
                  ...formData,
                  passOld : e.target.value
                })} value={formData.passOld} className="py-2 px-4 rounded-lg focus:border-2 focus:border-sky-400 outline-none border-2 boder-slate-50" type="password" name="passOld" id="passOld" placeholder="********" required />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <label className="text-slate-500" htmlFor="passNew">Password baru</label>
                <input onChange={e=>setFormData({
                  ...formData,
                  passNew : e.target.value
                })} value={formData.passNew} className="py-2 px-4 rounded-lg focus:border-2 focus:border-sky-400 outline-none border-2 boder-slate-50" type="password" name="passNew" id="passNew" placeholder="********" required />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <label className="text-slate-500" htmlFor="verPass">Konfirmasi password</label>
                <input onChange={e=>setFormData({
                  ...formData,
                  verPass : e.target.value
                })} value={formData.verPass} className="py-2 px-4 rounded-lg focus:border-2 focus:border-sky-400 outline-none border-2 boder-slate-50" type="password" name="verPass" id="verPass" placeholder="********" required />
              </div>
            </div>
          </div>
          <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
            <button id="btnSavePassword" type="submit" className="py-2 px-8 bg-sky-400 rounded-lg  text-white hover:bg-sky-500">
              {loading == 1 ? 'Proses simpan..' : 'Simpan'}
            </button>
          </div>
        </form>
      </div>
    </div>

  )
}

export default ModalPassword