import React,{useState} from 'react'
import { Helmet } from 'react-helmet'
import Notification, { notifySuccess, notifyError } from '../config/Notification'
import { mUser, mToken, fetchData } from '../config/Config'

const Info = () => {
  const [subject, setSubject] = useState('')
  const [content, setContent] = useState('')
  const [loading, setLoading] = useState(0)

  const handleSubmit=(e)=>{
    e.preventDefault()
    setLoading(1)
    if(subject == '' || content == ''){
      notifyError('Data tidak boleh kosong')
      setLoading(0)
      return
    }
    const formData = {subject,content}
    fetchSendMessage(formData)
  }

  const fetchSendMessage = async(formData)=>{
    try {
      const data = await fetchData(`member/${mUser.username}/suggestion`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${mToken}`,
          'Content-Type' : 'application/json',
        },
        body: JSON.stringify(formData)
      });
      if(data.success == true){
        notifySuccess(data.message)
        setContent('')
        setSubject('')
      }else{
        notifyError(data.message);
      }
      setLoading(0)
    } catch (error) {
      setLoading(0)
      notifyError(error.message)
    }
  }



  return (
    <div>
      <Helmet>
        <title>Info & Contact | Coding Asik Academy</title>
      </Helmet>
      <h3 className="font-semibold text-2xl text-center mt-8 mb-16">Kontak &amp; info</h3>
      <div className="lg:flex lg:gap-8 lg:mt-12 lg:items-start">
        <div className="mt-10 lg:w-2/5 lg:bg-slate-50 lg:mt-0 lg:p-8 lg:rounded-lg">
          <div>
            <h4 className="font-semibold text-slate-500 mb-4">Cara Penggunaan Web</h4>
            <div className="mb-8">
              <div className="w-full md:static sticky z-10 top-0 md:mt-8 ">
                <iframe className="w-full aspect-video bg-slate-300" src="https://www.youtube.com/embed/x3d1O2XoGZA?si=Wl76NdyLUoYFvW37" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
              </div>
            </div>
            <h4 className="font-semibold text-slate-500">Kontak &amp; Sosial media</h4>
            <div className="flex gap-4 my-6 justify-between">
              <a href="mailto:codingasik2020@gmail.com" target="_blank" className="h-12 w-full md:w-28 flex justify-center items-center gap-1 border border-sky-400 text-sky-400 rounded-lg hover:text-white hover:bg-sky-500 ">
                <i className="fa-solid fa-envelope" />
                <span>Email</span>
              </a>
              <a href="https://wa.me/6285713254744" target="_blank" className="h-12 w-full md:w-28 flex justify-center items-center gap-1 border border-green-400 text-green-400 rounded-lg hover:text-white hover:bg-green-500">
                <i className="fa-brands fa-whatsapp" />
                <span>Whatsapp</span>
              </a>
              <a href="https://discord.gg/Cx8m3avKuZ" target="_blank" className="h-12 w-full md:w-28 flex justify-center items-center gap-1 border border-sky-400 text-sky-400 rounded-lg hover:text-white hover:bg-sky-500">
                <i className="fa-brands fa-discord" />
                <span>Discord</span>
              </a>
            </div>
          </div>
          <div className="mt-8">
            <p className="font-semibold text-slate-500">Follow Coding Asik di:</p>
            <div className="flex gap-4 justify-between mt-6">
              <a href="https://instagram.com/codingasik" target="_blank" className="w-full h-12 border border-sky-400 text-sky-400 rounded-lg hover:text-white hover:bg-sky-500 flex flex-col gap-2 items-center justify-center">
                <i className="text-2xl fa-brands fa-instagram" />
              </a>
              <a href="https://youtube.com/codingasik" target="_blank" className="w-full border border-red-400 text-red-400 rounded-lg hover:text-white hover:bg-red-500 flex flex-col gap-2 items-center justify-center">
                <i className="text-2xl fa-brands fa-youtube" />
              </a>
              <a href="https://tiktok.com/@codingasik" target="_blank" className="w-full h-12 border border-black text-black rounded-lg hover:text-white hover:bg-black flex flex-col gap-2 items-center justify-center">
                <i className="text-2xl fa-brands fa-tiktok" />
              </a>
              <a href="https://github.com/codingasik" target="_blank" className="w-full h-12 border border-black text-black rounded-lg hover:text-white hover:bg-black flex flex-col gap-2 items-center justify-center">
                <i className="text-2xl fa-brands fa-github" />
              </a>
            </div>
          </div>
        </div>
        <div className="lg:w-3/5  mt-12  md:mt-4 lg:mt-0">
          <div className="md:bg-slate-50 mb-8 md:rounded-lg md:p-8">
            <hr className="mb-8 md:hidden" />
            <h4 className="font-semibold text-slate-500">Kritik dan saran</h4>
            <p className="mt-1 text-slate-400">Berikan masukan apapun baik sistem, materi dan lainnya, agar Coding Asik menjadi lebih baik lagi</p>
            <form onSubmit={handleSubmit} className="mt-8 lg:w-full">
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-2 w-full">
                  <label className="text-slate-500" htmlFor="subject">Perihal</label>
                  <input className="py-2.5 px-4 rounded-lg focus:border-2 focus:border-sky-400 outline-none border-2 boder-slate-50" type="text" name="subject" onChange={(e)=>setSubject(e.target.value)} value={subject} placeholder="Perihal" required />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <label className="text-slate-500" htmlFor="content">Isi kritik &amp; saran</label>
                  <textarea className="py-2.5 px-4 rounded-lg focus:border-2 focus:border-sky-400 outline-none border-2 boder-slate-50" type="text" name="content" onChange={(e)=>setContent(e.target.value)} value={content} placeholder="Inputkan kritik & saranmu" required  />
                </div>
              </div>
              <div className="w-full text-right mt-8">
                <button id="btnSend" type="submit" className="py-2 px-8 bg-sky-400 rounded-lg  text-white hover:bg-sky-500">{loading == 0 ? 'Kirim' : 'Sedang mengirim..'}</button>
              </div>
            </form>
          </div>
          <div className="md:bg-slate-50 md:rounded-lg md:p-8">
            <h4 className="font-semibold text-slate-500">Tentang Web</h4>
            <p className="mt-4 text-slate-500 flex flex-col md:flex-row gap-2">
              <span className="w-32">Version 3.0 :</span>
              <span className="w-full ">
              </span></p><p className="text-slate-500">
              Masih di konsep tema "Dashboard Minimalis", yaitu dengan tampilan admin dengan peningkatan peforma web.
            </p>
            <p className="text-slate-500 mt-3">
              Apa yang beda dengan versi sebelumnya?
            </p>
            <ul className=" list-decimal ml-5 mt-3 text-slate-500">
              <li>Penggatian teknologi web agar makin cepat dan ringan</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Info