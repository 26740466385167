import React, { useState } from 'react'
import ImageUploader from '../../../config/ImageUploader'
import { mUser, mToken, fetchData } from '../../../config/Config'
import Notification, { notifySuccess, notifyError } from '../../../config/Notification'
import ImageUploaderByUrl from '../../../config/ImageUploadByUrl'

const ModalUpload = ({ showModalUpload, setShowModalUpload }) => {
  const [uploadLink, setUploadLink] = useState(0)
  const [uploadDirect, setUploadDirect] = useState(0)
  const [image, setImage] = useState('')
  const [loadingLink, setLoadingLink] = useState(0);
  const [loadingDirect, setLoadingDirect] = useState(0);

  const handleUploadChoice = e => {
    e.preventDefault()
    const filter = e.target.getAttribute('data-filter');
    if (filter == 'link') {
      setUploadLink(1)
      setUploadDirect(0)
    } else {
      setUploadLink(0)
      setUploadDirect(1)

    }
  }

  const handleCloseModal =(e)=>{
    e.preventDefault()
    setShowModalUpload(0)
    setImage('')
    setUploadLink(0)
    setUploadDirect(0)
  }


  const handleImageUpload = (croppedBlob) => {
    const formData = new FormData();
    formData.append('image', croppedBlob, 'cropped_image.jpg');
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoadingDirect(1)
    const formData = new FormData();
    formData.append('image', image, 'cropped_image.jpg');
    formData.append('filter', 'direct');
    formData.append('imageOld', mUser.picture);
    fetchUploadImage(formData)
  }

  const handleImageUploadUrl = (imageUrl) => {

    const formData = new FormData();
    formData.append('image', imageUrl);
    formData.append('filter', 'link');
    fetchUploadImage(formData)
  };

  const fetchUploadImage = async (formData) => {
    try {
      const data = await fetchData(`member/${mUser.username}/change-image`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${mToken}`,
        },
        body: formData
      });
      if(data.success == true){
        notifySuccess(data.message)
        setShowModalUpload(0)
        setImage('')
        setUploadLink(0)
        setUploadDirect(0)
        setTimeout(() => {
          window.location.href = '/profile'
        }, 3000);
      }else{
        notifyError(data.message);
      }
      setLoadingDirect(0)
      setLoadingLink(0)
      if(data.data.image){
        let storedData = localStorage.getItem('mUser');
        if (storedData) {

          let dataObject = JSON.parse(storedData);
          dataObject.picture = data.data.image;
          localStorage.setItem('mUser', JSON.stringify(dataObject));

        }
      }
    } catch (error) {
      notifyError(error.message)
    }
  }

  
  
  return (
    <div className={`${!showModalUpload ? 'opacity-0 invisible scale-0 translate-x-40' : 'opacity-100 visible scale-100 translate-x-0'} fixed top-0 left-0 z-40 flex justify-center items-center w-full h-full overflow-y-auto bg-black bg-opacity-20 transition-all ease-in-out delay-300`}>
      <div className="w-full md:w-[28rem] p-8 bg-white shadow-lg rounded-lg text-center my-16 mx-4 relative ">
        <input type="hidden" id="uploadMethod" />
        <button onClick={handleCloseModal} type="button" className="absolute top-0 right-0 w-8 h-8 flex justify-center items-center bg-slate-100 text-red-500 rounded-md shadow-md"><i className="fa-solid fa-close" /></button>
        <div className=" flex gap-4 items-center w-full justify-center mt-4">
          <button onClick={handleUploadChoice} type="button" data-filter="link" className={`${uploadLink && 'bg-sky-400 text-white'} border-2 border-sky-400 py-2 px-4 rounded-md outline-none hover:bg-sky-500 hover:text-white`}>Upload By Link</button> Atau
          <button onClick={handleUploadChoice} type="button" data-filter="direct" className={`${uploadDirect && 'bg-sky-400 text-white'} border-2 border-sky-400 py-1.5 px-4 rounded-md outline-none hover:bg-sky-500 hover:text-white`}>Upload Langsung</button>
        </div>
        <div id="sectionUploadLink" className={`${!uploadLink && 'hidden'} mt-8 border-t-2 border-t-slate-200`}>
          <ImageUploaderByUrl loadingLink={loadingLink} setLoadingLink={setLoadingLink} onImageUpload={handleImageUploadUrl} />
        </div>
        <div id="sectionUploadDirect" className={`${!uploadDirect && 'hidden'} mt-8 border-t-2 border-t-slate-200`}>
          <ImageUploader loadingDirect={loadingDirect} setLoadingDirect={setLoadingDirect}  setImage={setImage} onImageUpload={handleImageUpload} handleSubmit={handleSubmit} />
        </div>
 
      </div>
    </div>

  )
}

export default ModalUpload