import React, { useState } from 'react'
import CourseDownloadStore from '../../../store/CourseDownloadStore';

const DetailCourse = ({ slug, selectedCourse, lessonNow }) => {


  const { downloads, fetchDownloads, loading } = CourseDownloadStore();

  const [tab, setTab] = useState({
    description: 1,
    download: 0,
    about: 0
  })

  const handleTab = val => {
    if (val == 'description') {
      setTab({
        description: 1,
        download: 0,
        about: 0
      })
    } else if (val == 'download') {
      setTab({
        description: 0,
        download: 1,
        about: 0
      })
      fetchDownloads(slug)
    } else if (val == 'about') {
      setTab({
        description: 0,
        download: 0,
        about: 1
      })
    }
  }
  return (
    <div>
      <style>
        {
          `
          .textdesc ul{
            list-style: decimal;
            margin-left: 2rem;
          }
          .textdesc a{
            text-decoration: underline;
            color: #38BDF8;
            cursor: pointer;
            margin-top: 8rem;
          }
          `
        }

      </style>
      <ul className="nav nav-tabs nav-justified flex overflow-x-auto list-none pl-0 mb-8 border border-slate-200 mt-8 rounded-lg bg-white" role="tablist">
        <li className="nav-item flex-grow text-center pl-2" role="presentation">
          <button onClick={() => handleTab('description')} type='button' className={`${tab.description && 'bg-gray-100 '} nav-link w-full block font-medium leading-tight border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent`} >Deskripsi</button>
        </li>
        <li className="nav-item flex-grow text-center" role="presentation">
          <button onClick={() => handleTab('download')} type='button' className={`${tab.download && 'bg-gray-100 '} nav-link w-full block font-medium leading-tight border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent`}>Download</button>
        </li>
        <li className="nav-item flex-grow text-center pr-2" role="presentation">
          <button onClick={() => handleTab('about')} type='button' className={`${tab.about && 'bg-gray-100 '} nav-link w-full block font-medium leading-tight border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent`} ><span className="whitespace-nowrap">Tentang Course</span></button>
        </li>
      </ul>
      <div className="tab-content" id="tabs-tabContentJustify">
        <div className={`${!tab.description && 'hidden'} tab-pane fade show `} id="tabs-deskripsi" role="tabpanel" aria-labelledby="tabs-home-tabJustify">
          <div className="my-8 bg-white rounded-lg p-4 md:p-8">
            <h4 className="font-semibold">Deskripsi</h4>
            <div className="border-t border-t-slate-300 mt-4 pt-4 flex flex-col gap-2 textdesc" dangerouslySetInnerHTML={{ __html: lessonNow && lessonNow.description }} />
          </div>
        </div>
        <div className={`${!tab.download && 'hidden'} tab-pane fade show `} id="tabs-profileJustify" role="tabpanel" aria-labelledby="tabs-profile-tabJustify">
          <div className="my-8 bg-white rounded-lg p-4 md:p-8">
            <h4 className="font-semibold">Download</h4>
            <div className="border-t border-t-slate-300 mt-4 pt-4 flex flex-col gap-4" id="courseFiles">
              {
                !loading ? <div className="animate-pulse flex space-x-4 w-full">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 bg-slate-200 rounded" />
                    <div className="h-2 bg-slate-200 rounded" />
                    <div className="h-2 bg-slate-200 rounded" />
                  </div>
                </div> : downloads.map(item => (
                  <div key={item.id} className="p-4 rounded-lg bg-slate-50 flex justify-between items-center border-l-2 border-l-sky-400">
                    <div className="flex flex-col gap-2">
                      <div className='flex gap-2'>
                        <i className="fa-solid fa-check mt-1" />
                        <div>
                          <h4 className="text-sky-500"> {item.file_name}</h4>
                          <p className='text-sm mt-1'>{item.note}</p>
                        </div>
                      </div>
                      <p className="text-sm text-slate-500 ml-5">{item.file_note}</p>
                    </div>
                    <a href={item.file_url} target='_blank' className="border border-sky-400 p-2 rounded-lg flex justify-center items-center text-sky-400 gap-2 text-sm hover:bg-sky-400 hover:text-white" title='Menuju link'><i className="fa-solid fa-download" /><span className="hidden md:inline-block">Download</span></a>
                  </div>
                ))
              }




            </div>
          </div>
        </div>
        <div className={`${!tab.about && 'hidden'} tab-pane fade show `} id="tentang" role="tabpanel" aria-labelledby="tabs-profile-tabJustify">
          <div className="my-8 bg-white rounded-lg p-4 md:p-8">
            <h4 className="font-semibold">Tentang Course</h4>
            <div className="border-t border-t-slate-300 mt-4 pt-4 flex flex-col gap-2 textdesc" dangerouslySetInnerHTML={{ __html: selectedCourse && selectedCourse.description }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailCourse