import React from 'react'

const TabReview = ({ reviews, handleTab, tab }) => {
  let no = 1;
  return (
    <li>
      <button data-target="review" onClick={() => handleTab('review')}
        className={`${!tab.review ? 'bg-white' : 'bg-sky-400 text-white'} tab absolute top-0 left-44 ml-11 inline-block px-4 py-2  mb-2 shadow-md rounded-lg hover:bg-sky-400 hover:text-white outline-none`}>
        <h2>Review</h2>
      </button>
      <div data-id="review" className={`${!tab.review && 'hidden'} tab-body mt-14 `}>
        <div className="flex flex-col overflow-x-auto bg-white w-full">
          <div className="w-full">
            <table className="w-full text-left text-sm font-light">
              <thead className="font-medium border-b border-b-neutral-300">
                <tr>
                  <th scope="col" className="px-6 py-4">No</th>
                  <th scope="col" className="px-6 py-4">Course Review</th>
                  <th scope="col" className="px-6 py-4">Tanggal Review</th>
                  <th scope="col" className="px-6 py-4">Rating</th>
                  <th scope="col" className="px-6 py-4">Review</th>
                </tr>
              </thead>
              <tbody id="bodyReview">
                {
                  reviews == '' ? <tr className="border-b border-b-neutral-300">
                    <td className="whitespace-nowrap px-6 py-4 font-medium" colSpan={5}>
                      <div className="w-full ">
                        <div className="animate-pulse flex space-x-4">
                          <div className="flex-1 space-y-6 py-1">
                            <div className="h-2 bg-slate-200 rounded" />
                            <div className="h-2 bg-slate-200 rounded" />
                            <div className="h-2 bg-slate-200 rounded" />
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr> :
                  reviews.map(item => (
                    <tr key={item.id} class="border-b border-b-neutral-300">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">{no++}</td>
                      <td class="whitespace-nowrap px-6 py-4">{item.course_name}</td>
                      <td class="whitespace-nowrap px-6 py-4">{item.review_at == '0000-00-00 00:00:00' ? '-' : item.review_at}</td>
                      <td class="whitespace-nowrap px-6 py-4">{item.rating == 0 ? '-' : item.rating}</td>
                      <td class="px-6 py-4"><p className='w-96'>{item.review == '' ? '-' : item.review}</p></td>
                    </tr>
                  ))
                }

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </li>
  )
}

export default TabReview