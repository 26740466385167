import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getOrCreateDeviceId, getUserAgent,BASE_URL_API } from '../config/Config';
import Notification, { notifySuccess, notifyError } from '../config/Notification';
import logoFull from '../assets/img/brand/logo-full.png'
import { AuthContext } from '../context/AuthContext';

const AuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useContext(AuthContext)
  const [error,setError] = useState('')

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');

    if (code) {
      fetchUrl(code)
    }
  }, [navigate]);

  const fetchUrl = async (code) => {
    try {
      const response = await fetch(`${BASE_URL_API}/auth/callback?code=${code}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'M-DEVICE-ID' : getOrCreateDeviceId(),
          'M-OS' : getUserAgent().os,
          'M-BROWSER' : getUserAgent().browser,
        },
      });

      const data = await response.json();

      if (data.success) {
        const { mToken, mUser } = data.data;
        await login(mToken, mUser);
        const checkUser = await JSON.parse(localStorage.getItem('mUser'));
        if(checkUser){
          window.location.href = '/';
        }
      } else {
        throw new Error(data.message)
      }

    } catch (error) {
      console.error('Logout error:', error);
      notifyError(error.message)
      setError(error.message)
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    }
  }

  return (
    <main id="pageVerfication">
      <section className="fixed left-0 top-0 w-full h-full flex items-center justify-center bg-white">
        <div className="w-full flex flex-col gap-8 items-center justify-center ">
          <div className="flex flex-col justify-center gap-16 items-center">
            {
              error == '' ? <div className="flex gap-4 flex-col items-center">
              <div className="flex items-center justify-center  space-x-2">
                <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce" />
                <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce delay-200" />
                <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce delay-400" />
              </div>
              <p>Proses verifikasi data ...</p>
            </div> : <div className='bg-red-400 p-4 m-4 rounded-lg text-white'>{error}</div>
            }
            
            <img className="w-36 md:w-40" src={logoFull} alt="Logo" />
          </div>
        </div>
      </section>
    </main>

  );
};

export default AuthCallback;
