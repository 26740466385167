import { create } from "zustand"
import { mUser,mToken,fetchData } from "../config/Config";

const CourseRatingsStore = create(set =>({
  ratings : {},
  filter : '',
  fetchRatings : async(filter)=>{
    try {
      const data = await fetchData(`courses/ratings?id=${filter}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${mToken}`,
        }
      });
      set({
        ratings: data.data,
        filter: filter,
      });
    } catch (error) {
      console.log(error)
    }
  }
}))

export default CourseRatingsStore