import React, { useContext, useEffect, useState } from 'react'
import logoFull from '../assets/img/brand/logo-full.png'
import googleIcon from '../assets/img/etc/google-icon.png'
import { Link, useNavigate } from 'react-router-dom'
import { fetchData, getOrCreateDeviceId, getUserAgent,BASE_URL_API } from '../config/Config'
import { AuthContext } from '../context/AuthContext'
import Notification, { notifySuccess, notifyError } from '../config/Notification'
import { UAParser } from 'ua-parser-js'


const Login = () => {
  const [formData,setFormData] = useState({
    username : '',
    password : ''
  })
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const [showPassword,setShowPassword] = useState(0);
  const [loading,setLoading] = useState(0);
  const [authUrl, setAuthUrl] = useState('')
  useEffect(()=>{
  },[formData])


  const handleShowPassword=()=>{
    showPassword ? setShowPassword(0) : setShowPassword(1);
  }

  const handleLogin = async(e) =>{
    e.preventDefault()
    setLoading(1)
    try {
      const response = await fetch(`${BASE_URL_API}/login`,{
        method : 'POST',
        credentials: 'include',
        headers : {
          'Content-Type' : 'application/json',
          'Accept' : 'application/json',
          'M-DEVICE-ID' : getOrCreateDeviceId(),
          'M-OS' : getUserAgent().os,
          'M-BROWSER' : getUserAgent().browser,
        },
        body : JSON.stringify(formData)
      });

      const data = await response.json();

      data.success == true ? notifySuccess(data.message) : notifyError(data.message);
      setLoading(0)
      const { mToken, mUser } = data.data;
      login(mToken, mUser);
      setTimeout(() => {
        window.location.href = '/';
      }, 2000); 
    } catch (error) {
      setLoading(0)
      //notifyError(error.message)
    }
    
  }


  const [loadingGoogleBtn, setLoadingGoogleBtn] = useState(0);

  const fetchUrl = async()=>{
    try {
      const data = await fetchData(`auth/google`,{
        method : 'GET',
        credentials: 'include',
        headers : {
          'Content-Type' : 'application/json',
          'Accept' : 'application/json'
        },
      });
      setLoadingGoogleBtn(0)
      console.log(data)
      if(data.success){
        setAuthUrl(data.data.authUrl)
        window.location.href = data.data.authUrl;
      }else{
        throw new Error(data.message)
      }
    } catch (error) {
      console.error('Logout error:', error);
      notifyError(error.message)
    }
  }

  const handleLoginGoogle = (e) => {
    e.preventDefault()
    setLoadingGoogleBtn(1)
    fetchUrl()
    // Redirect user to Laravel's Google login route
    
  };

  return (
    <main id="pageLogin">
      <div className='h-screen bg-cover bg-center -z-10 fixed w-full' style={{ backgroundImage: `url(${'https://images.unsplash.com/photo-1517134191118-9d595e4c8c2b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'})` }}>

      </div>
      <div className="p-4 ">
        <div className="w-full md:w-[26rem] mx-auto px-4 py-8 border border-slate-200 rounded-lg bg-slate-100 my-16 lg:my-32 bg-opacity-85">
          <div className="w-full flex justify-center flex-col items-center gap-6">
            <img className="w-40" src={logoFull} alt="Coding Asik" />
            <h3 className="text-xl text-slate-500 font-semibold">Login</h3>
            <p className="text-slate-400 text-sm -mt-4">Login ke Member Area</p>
          </div>
          <hr className="mt-8 mb-4" />
          <div className="mt-8 ">
            <form onSubmit={handleLogin} id="formLogin">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2 w-full">
                  <label className="text-slate-500" htmlFor="username">Username atau Email</label>
                  <input onChange={e=>setFormData({...formData,username : e.target.value})} value={formData.username} className="py-2 px-4 rounded-lg focus:border-2 focus:border-sky-400 outline-none border-2 boder-slate-50" type="text" name="username" id="username" placeholder="Input username atau email" autoFocus required  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <label className="text-slate-500" htmlFor="password">Password</label>
                  <div className="flex gap-4 justify-between items-center">
                    <input onChange={e=>setFormData({...formData,password : e.target.value})} value={formData.password} className="w-full py-2 px-4 rounded-lg focus:border-2 focus:border-sky-400 outline-none border-2 boder-slate-50" type={`${!showPassword ? 'password' : 'text'}`} name="password" id="password" placeholder="********" required  />
                    <button onClick={handleShowPassword} type="button" className="flex justify-center items-center w-8 h-8 text-sky-400"><i className={`fa-solid  ${!showPassword ? 'fa-eye-slash' : 'fa-eye'}`} /></button>
                  </div>
                </div>
              </div>
              <div className="w-full text-right mt-4">
                <Link to="/forget-password" className="text-sky-400 underline text-sm">Lupa password?</Link>
              </div>
              <div className="w-full mt-6">
                <button type="submit" className="w-full bg-sky-400 py-2 px-3 rounded-lg text-white hover:bg-sky-500 ">{!loading ? 'Login' : 'Checking data...'}</button>
              </div>
            </form>
            <div className="text-center my-8">Atau</div>
            <div className="w-full">
              <button onClick={handleLoginGoogle} id="btnGoogle" type="button" className="cursor-pointer w-full bg-white h-11 rounded-lg hover:shadow-md flex gap-2 items-center justify-center text-slate-400 transition-all ease-in-out duration-200"><img className="w-6" src={googleIcon} alt="Google" /> {!loadingGoogleBtn ? 'Login with Google' : 'Proses login ...'}</button>
            </div>
          </div>
        </div>
      </div>
    </main>


  )
}

export default Login