import { create } from "zustand"
import { mUser,mToken,fetchData } from "../config/Config";

const CourseReviewsStore = create(set =>({
  filter : '',
  reviews : [],
  currentPage : 1,
  itemsPerPage : 10,
  totalPages : 0,
  totalData : 0,
  from : 0,
  to : 0,
  lastPage : 0,
  loadingScreen : 0,
  setPage : (page) =>set({currentPage : page }),
  fetchReviews : async(currentPage,itemsPerPage,filter)=>{
    try {
      const data = await fetchData(`courses/reviews?page=${currentPage}&limit=${itemsPerPage}&course=${filter}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${mToken}`,
        }
      });
      set({
        reviews: data.data.data,
        itemsPerPage : data.data.per_page,
        totalPages : data.data.last_page,
        totalData : data.data.total,
        from : data.data.from,
        to : data.data.to,
        lastPage : data.data.last_page,
        loadingScreen : 1,
      });
    } catch (error) {
      console.log(error)
    }
  }
}))

export default CourseReviewsStore