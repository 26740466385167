import React from 'react'
import wave from '../assets/img/etc/wave-up.svg'
import logoSquare from '../assets/img/brand/logo-square.png'
const Header = ({bar}) => {
  return (
    <div id="sectionLogo" className={`${bar && 'lg:w-60'} relative -mb-10 md:m-0 md:bg-slate-600 md:h-20 md:flex md:items-center md:fixed md:top-0 md:z-20 md:w-24 text-white`}>
      <div className=" md:px-4 md:flex md:gap-2 md:items-center">
        <img className="w-full md:hidden" src={wave} alt="Background wave" />
        <img className="w-14 h-14 bg-white p-1.5 rounded-full absolute top-12 left-8 md:static md:p-1 md:ml-2 md:h-12 md:w-12" src={logoSquare} alt="Logo" />
        <h2 id="sectionLogoTitle" className={`${bar && 'lg:block'} hidden md:hidden  font-semibold text-lg`}>Coding Asik</h2>
      </div>
    </div>
  )
}

export default Header