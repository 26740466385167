import React from 'react'
import { NavLink } from 'react-router-dom'

const Menu = ({handleMinimaze,bar}) => {
  return (
    <div>
      <button onClick={handleMinimaze} className={`${bar && 'lg:left-52'} hidden lg:block fixed top-1/2 -translate-y-1/2 md:left-16 z-40 ml-3 bg-slate-200 w-10 h-10 rounded-full transition-colors ease-in-out delay-150 hover:bg-slate-300 border-none`}><i className={`${bar ? 'fa-left-long' : 'fa-right-long'} fa-solid text-slate-600`} title="Minimaze Menu" /></button>
      <div className={`${bar && 'lg:w-60' } fixed bottom-0 w-full py-3 px-6 flex justify-between bg-white text-slate-300 border-t-2 border-slate-200 md:top-0 md:left-0 md:w-24  md:flex-col md:h-full md:justify-start  md:items-start md:mt-20 md:bg-slate-600 md:border-t-slate-300 md:gap-2 md:py-4 md:px-4 z-20`}>
        <NavLink to="/"
          className={({ isActive }) => `${isActive ? 'md:bg-slate-700 text-sky-400' : ''} ${bar && 'lg:justify-start'} navbar-item flex flex-col gap-2 justify-center items-center p-1 md:flex-row md:hover:bg-slate-700 md:w-full md:rounded-md md:justify-center md:p-4 hover:text-sky-400`} title="Home">
          <i className="fa-solid fa-house w-5 h-5 text-center" />
          <span className={`${bar && 'lg:inline-block'} text-xs md:hidden md:text-base`}>Home</span>
        </NavLink>
        <NavLink to="/product" 
          className={({ isActive }) => `${isActive ? 'md:bg-slate-700 text-sky-400' : ''} ${bar && 'lg:justify-start'} navbar-item flex flex-col gap-2 justify-center items-center p-1 md:flex-row md:hover:bg-slate-700 md:w-full md:rounded-md md:justify-center md:p-4 hover:text-sky-400`} href="course.html" title="Courses">
          <i className="fa-solid fa-bookmark  w-5 h-5 text-center" />
          <span className={`${bar && 'lg:inline-block'} text-xs md:hidden md:text-base`}>Product</span>
        </NavLink>
        <NavLink to="/review" 
          className={({ isActive }) => `${isActive ? 'md:bg-slate-700 text-sky-400' : ''} ${bar && 'lg:justify-start'} navbar-item flex flex-col gap-2 justify-center items-center p-1 md:flex-row md:hover:bg-slate-700 md:w-full md:rounded-md md:justify-center md:p-4 hover:text-sky-400`} href="review.html" title="Review">
          <i className="fa-solid fa-star w-5 h-5 text-center" />
          <span className={`${bar && 'lg:inline-block'} text-xs md:hidden md:text-base`}>Review</span>
        </NavLink>
        <NavLink to="/profile" 
          className={({ isActive }) => `${isActive ? 'md:bg-slate-700 text-sky-400' : ''} ${bar && 'lg:justify-start'} navbar-item flex flex-col gap-2 justify-center items-center p-1 md:flex-row md:hover:bg-slate-700 md:w-full md:rounded-md md:justify-center md:p-4 hover:text-sky-400`} href="profil.html" title="Profile">
          <i className="fa-solid fa-user w-5 h-5 text-center" />
          <span className={`${bar && 'lg:inline-block'} text-xs md:hidden md:text-base`}>Profile</span>
        </NavLink>
        <NavLink to="/info" 
          className={({ isActive }) => `${isActive ? 'md:bg-slate-700 text-sky-400' : ''} ${bar && 'lg:justify-start'} navbar-item flex flex-col gap-2 justify-center items-center p-1 md:flex-row md:hover:bg-slate-700 md:w-full md:rounded-md md:justify-center md:p-4 hover:text-sky-400`} href="setting.html" title="Pengaturan">
          <i className="fa-solid fa-circle-info w-5 h-5 text-center" />
          <span className={`${bar && 'lg:inline-block'} text-xs md:hidden md:text-base`}>Kontak &amp; Info</span>
        </NavLink>
      </div>
    </div>
  )
}

export default Menu