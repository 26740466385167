import React, { useEffect, useState } from 'react'
import LessonCollapse from './LessonCollapse'

const PlaylistCollapse = ({lessonNow,setLessonNow,playlist,lessons,dataRequired}) => {

  const [isOpen, setIsOpen] = useState(false)
  const [activePlaylist, setActivePlaylist] = useState('')
  const toogleCollapse = (id) => {
    activePlaylist != id ? setActivePlaylist(id) : setActivePlaylist([])
  }

  const handleLessonClick=(lessonId)=>{
    const getData = lessons.filter(l => l.id == lessonId)
    setLessonNow(getData[0])
  }

  const filterLessons = lessons.filter(lesson => lesson.playlist_id == playlist.id)

  useEffect(()=>{
    if(lessonNow){
      setActivePlaylist(lessonNow.playlist_id);
    }
    
  },[lessonNow])

  return (
    <div key={playlist.id} className="rounded-lg bg-white border border-slate-200 w-full">
      <h2 className="border border-b-slate-300" id="heading1">
        <button onClick={()=>toogleCollapse(playlist.id)} className="btn-collapse relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none transition focus:outline-none" type="button">
          <i className={`absolute p-2 right-4 fa-solid ${activePlaylist == playlist.id ? 'fa-chevron-down' : 'fa-chevron-up'}`}></i>
          {playlist.playlist_name}
        </button>
      </h2>
   
      <div className={activePlaylist != playlist.id && 'hidden'} key={playlist.id}>
        <div className="px-2 py-4 flex flex-col gap-4">
          {

            filterLessons.length > 0 && (
              filterLessons.map(lesson=>(
                  <LessonCollapse lessonNow={lessonNow}  handleLessonClick={handleLessonClick} lesson={lesson} dataRequired={dataRequired}/>
              ))
            )
            
          }
    
        </div>
      </div>


    </div>
  )
}

export default PlaylistCollapse