import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Komponen Notifikasi
const Notification = () => {
  return <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />;
};

// Fungsi untuk menampilkan notifikasi
export const notifySuccess = (message) => {
  toast.success(message);
};

export const notifyError = (message) => {
  toast.error(message);
};

export default Notification;
