import { create } from "zustand"
import { mUser,mToken,fetchData } from "../config/Config";

const CourseDownloadStore = create(set =>({
  downloads : [],
  loading : 0,
  fetchDownloads : async(slug)=>{
    try {
      const data = await fetchData(`course/${slug}/files`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${mToken}`,
        }
      });
      if(data.success){
        set({ 
          downloads: data.data,
          loading : 1
        });
      }
    } catch (error) {
      console.log(error)
    }
  }
}))

export default CourseDownloadStore