import { create } from "zustand"
import { mUser, mToken, fetchData } from "../config/Config";

const MemberHistoriesStore = create(set =>({
  history : [],
  loadingHistory : 0,
  fetchHistory : async()=>{
    try {
      const data = await fetchData(`member/${mUser.username}/history`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${mToken}`,
        }
      });
      data.success ? set({
        history: data.data,
        loadingHistory : 1
      }) : set({
        loadingHistory : 1
      })
      
    } catch (error) {
      console.log(error)
    }
  }
}))

export default MemberHistoriesStore