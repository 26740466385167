import React, { useEffect, useState } from 'react'
import PlaylistCollapse from './PlaylistCollapse'
import ModalReview from './ModalReview'

const MateriCourse = ({rating,setDataRequired, selectedCourse, lessonNow, setLessonNow, playlists, lessons,dataRequired }) => {

  const [isShowModalReview, setIsShowModalReview] = useState(0)
  const [isMinimaze, setIsMinimaze] = useState(0)
  const [isShowMateri, setIsShowMateri] = useState(0)
  const [isActiveMenuBtn, setIsActiveMenuBtn] = useState(0)


  return (
    <div>
      <aside id="menuMateri" className={`${isMinimaze && 'hidden'} ${isShowMateri ? 'opacity-100 visible scale-100' : 'opacity-0 invisible scale-0' } md:ml-8 px-4 py-4 md:py-8 md:mt-8 w-[320px] md:w-[380px] lg:w-[440px] bg-slate-200 rounded-lg fixed md:static top-0 right-0 z-40 md:z-30 h-full md:h-auto   md:opacity-100 md:visible transition-all ease-in-out duration-150  md:scale-100`}>


        <button id="btnSideBarMinimaze" onClick={()=>setIsMinimaze(1)} className={`${isMinimaze ? 'fixed' : 'absolute'} hidden md:block  top-4 -translate-y-1/2 left-0 -ml-3 z-40 bg-sky-400 w-8 h-8 rounded-full transition-colors ease-in-out delay-150 hover:bg-slate-300 border-none`}><i className="fa-solid fa-right-long text-white" title="Minimaze Menu" /></button>
        <div className="flex justify-end md:hidden fixed right-0 top-1/2 z-30 mr-80">

          <button id="btnCloseMenuMateri" onClick={()=>setIsShowMateri(0)} className="flex justify-center items-center w-7 h-7 text-lg bg-red-400 hover:bg-red-500 text-white rounded-lg "><i className="fa-solid fa-close" /></button>
        </div>


        <div className="overflow-y-auto md:overflow-visible h-full">
          <div className="flex items-center justify-between px-2 my-4  md:mt-0">
            <h3 className="text-lg text-black w-1/2">Materi kelas</h3>
            <div className="w-1/2 flex flex-col gap-2 items-end">
              <p className="text-sm">Progress belajar</p>
              <div className="w-2/3 bg-white rounded-full">
                <div className="bg-blue-600 text-xs font-medium text-blue-200 text-center p-0.5 leading-none rounded-l-full" style={{ width: `${selectedCourse && Math.round(selectedCourse.progress)}%` }}> {selectedCourse && Math.round(selectedCourse.progress)}%</div>
              </div>
            </div>
          </div>
          <div className="py-4 flex flex-col gap-4 ">
            <div className="accordion ">
              {
                playlists.map(playlist => <PlaylistCollapse lessonNow={lessonNow} setLessonNow={setLessonNow} playlist={playlist} lessons={lessons} dataRequired={dataRequired} />)
              }
            </div>
          </div>
        </div>
      </aside>

            
      <button onClick={()=>setIsMinimaze(0)} title='Open Materi' className={`${isActiveMenuBtn == 0 ? 'translate-x-10 opacity-0 invisible' : 'translate-x-0 opacity-100 visible'} ${!isMinimaze && 'md:hidden'} z-30 transition-all delay-150  bg-orange-400 hover:bg-orange-500 fixed right-6 md:right-10 ${rating > 0 ? 'bottom-48' : 'bottom-60'} h-11 w-11 flex justify-center items-center   text-white rounded-full shadow-md`}><i className='fa-solid fa-book'></i></button>            

      <button  onClick={()=>setIsShowMateri(1)} title='Open Materi' className={`${isActiveMenuBtn == 0 ? 'translate-x-10 opacity-0 invisible' : 'translate-x-0 opacity-100 visible'} z-30 transition-all delay-150  bg-orange-400 hover:bg-orange-500 fixed right-6 md:right-10 ${rating > 0 ? 'bottom-48' : 'bottom-60'} h-11 w-11 flex justify-center items-center   text-white rounded-full shadow-md md:hidden`}><i className='fa-solid fa-book'></i></button>
            
      <button onClick={()=>setIsShowModalReview(1)} title='Review Course' className={`${isActiveMenuBtn == 0 ? 'translate-x-10 opacity-0 invisible' : 'translate-x-0 opacity-100 visible'} ${rating > 0 && 'hidden'} z-30 transition-all delay-100  bg-yellow-400 hover:bg-yellow-500 fixed right-6 md:right-10 bottom-48 h-11 w-11 flex justify-center items-center   text-white rounded-full shadow-md`}><i className='fa-solid fa-pen-to-square'></i></button>
      
      <a href="https://discord.gg/Cx8m3avKuZ" target="_blank" title='Join Group Discord' className={`${isActiveMenuBtn == 0 ? 'translate-x-10 opacity-0 invisible' : 'translate-x-0 opacity-100 visible'} z-30 transition-all delay-75  bg-blue-400 hover:bg-blue-500 fixed right-6 md:right-10 bottom-36 h-11 w-11 flex justify-center items-center   text-white rounded-full shadow-md`}><i className='fa-brands fa-discord'></i></a>

      <a href="https://chat.whatsapp.com/HHZIZaBdJRWBGN1lCFQWv9" target="_blank" title='Join Group Whatsapp' className={`${isActiveMenuBtn == 0 ? 'translate-x-10 opacity-0 invisible' : 'translate-x-0 opacity-100 visible'} z-30 transition-all delay-0  bg-green-400 hover:bg-green-500 fixed right-6 md:right-10 bottom-24 h-11 w-11 flex justify-center items-center text-lg  text-white rounded-full shadow-md`}><i className='fa-brands fa-whatsapp'></i></a>
      

      <button onClick={()=>setIsActiveMenuBtn(!isActiveMenuBtn)} title={`${isActiveMenuBtn == 0 ? 'Open Menu' : 'Close Menu'}`} className={`${isActiveMenuBtn == 0 ? 'bg-orange-400  hover:bg-orange-500' : 'bg-purple-400  hover:bg-purple-500 '} z-30 text-white fixed right-6 md:right-10 bottom-12 h-11 w-11 flex justify-center items-center text-lg   rounded-full shadow-md`}><i className={`fa-solid ${isActiveMenuBtn == 0 ? 'fa-ellipsis-vertical' : 'fa-ellipsis'}`}></i></button>



      <ModalReview rating={rating} setDataRequired={setDataRequired} selectedCourse={selectedCourse} isShowModalReview={isShowModalReview} setIsShowModalReview={setIsShowModalReview} dataRequired={dataRequired} />
    </div>

  )
}

export default MateriCourse