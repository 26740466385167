import React, { useEffect, useState } from 'react'
import icStartEmpty from '../../../assets/img/etc/ic_star_empty.svg';
import icStartFull from '../../../assets/img/etc/ic_star_full.svg';
import reviewImage from '../../../assets/img/hero/review-image.png';
import Notification,{ notifySuccess, notifyError } from '../../../config/Notification'
import { mUser, mToken, fetchData } from '../../../config/Config'
import { useNavigate } from 'react-router-dom';

const ModalReview = ({rating, setDataRequired,selectedCourse, isShowModalReview, setIsShowModalReview, dataRequired }) => {
  const navigate = useNavigate()
  const [star, setStar] = useState(0)
  const [ratingCaption, setRatingCaption] = useState('')
  const [review, setReview] = useState('');
  const [inputCount, setInputCount] = useState(255)
  useEffect(()=>{
    if(rating == 0){
      setTimeout(() => {
        setIsShowModalReview(1)
      }, 1 * 5 * 1000)
    }
  },[rating])

  const handleRatingClick = (e)=>{
    const id = e.target.value
    setStar(id)
    if(id == 1){
      setRatingCaption('Tidak membantu 🙁');
    }else if(id == 2){
      setRatingCaption('Belum membantu 🤔');
    }else if(id == 3){
      setRatingCaption('Membantu 🙂');   
    }else if(id == 4){
      setRatingCaption('Cukup membantu 😇');
    }else if(id == 5){
      setRatingCaption('Sangatlah membantu 🤩');  
    }
    
  }

  const handleReviewKeyUp =()=>{
    setInputCount(255 - parseInt(review.length))
  }

  const handleChangeReview=(e)=>{
    const val = e.target.value
    setReview(val)
  }

  const handleClickClose=()=>{
    setIsShowModalReview(0)
    setStar(0)
    setRatingCaption('')
    setReview('');
    setInputCount(255)
  }

  const handleSubmit=e=>{
    e.preventDefault()
    
    if(star == '' || review == '' || selectedCourse.id == ''){
      return notifyError('Data tidak boleh kosong');
    }
    if(selectedCourse.progress <= 30){
      handleClickClose()
      return notifyError('Pastikan progress >= 30%');
    }
    //const data = {star, review };
    const formData = {
      rating : star,
      review : review,
      courseId : selectedCourse.id,
      progress: selectedCourse.progress
    }
    fetchSendReview(formData);
  }

  const fetchSendReview = async(formData)=>{
    try {
      const data = await fetchData(`member/${mUser.username}/review`,{
        method: 'POST',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${mToken}`,
          'Content-Type' : 'application/json'
        },
        body : JSON.stringify(formData)
      });
      if(data.success == true ){
        setDataRequired('')
        notifySuccess(data.message) 
        navigate('/review')
      }else{
        notifyError(data.message)
      }
    } catch (error) {
      notifyError(error.message)
    }
  }

  return (
    <>
      <style>
        {
          `
          .star{margin-bottom:1rem;display:flex;flex-direction:row-reverse;align-items:center;justify-content:center;gap:.5rem}
          .star input{display:none}
          .star label{aspect-ratio:1/1;width:2.25rem;background-image:url(${icStartEmpty});background-size:contain;background-repeat:no-repeat;transition-property:all;transition-timing-function:cubic-bezier(.4,0,.2,1);transition-duration:75ms;transition-timing-function:cubic-bezier(.4,0,1,1)}
          .star input:not(:checked)~label:hover,.star input:not(:checked)~label:hover~label{background-image:url(${icStartFull})}input:checked~label{background-image:url(${icStartFull})}
        `
        }
      </style>

      <div id="modalReview" className={`${isShowModalReview ? 'opacity-100 visible scale-100 translate-x-0' : 'opacity-0 invisible scale-0 translate-x-40'} fixed top-0 left-0 z-40 flex justify-center items-start w-full h-full overflow-y-auto transition-all ease-in-out delay-300  bg-black bg-opacity-20`}>


        <form onSubmit={handleSubmit} className="w-full md:w-[28rem] p-8 bg-white shadow-lg rounded-lg text-center my-16 mx-4">
          <div className="flex justify-center mb-4">
            <img className="w-56" src={reviewImage} alt="Hero" />
          </div>
          <h2 className="text-lg font-semibold mb-4">Pendapatmu sangat penting untuk kami</h2>
          <p>
            Kami bekerja keras untuk membuat materi, fitur dll yang terbaik untukmu, dan kami ingin kamu tau itu. Oleh karena itu, dapatkah kamu memberikan rating dan review tentang materi, fitur, pelayanan dll yang kami berikan? 🙂 Agar bisa menjadi lebih baik lagi
          </p>
          <div className="star my-6">
            <input className type="radio" name="rating" id="rating5" value={5} onChange={handleRatingClick} checked={star == 5}/>
            <label htmlFor="rating5" />
            <input type="radio" name="rating" id="rating4" value={4}  onChange={handleRatingClick} checked={star == 4}/>
            <label htmlFor="rating4" />
            <input type="radio" name="rating" id="rating3" value={3} onChange={handleRatingClick} checked={star == 3}/>
            <label htmlFor="rating3" />
            <input type="radio" name="rating" id="rating2" value={2} onChange={handleRatingClick} checked={star == 2}/>
            <label htmlFor="rating2" />
            <input type="radio" name="rating" id="rating1" value={1} onChange={handleRatingClick} checked={star == 1}/>
            <label htmlFor="rating1" />
          </div>
          <div id="secRatingCaption" className={`${ratingCaption == '' && 'hidden'} w-full flex justify-center my-4`}>
            <h2 id="ratingCaption" className="w-44 text-center text-white bg-green-500 text-xs p-1 rounded-full">{ratingCaption}</h2>
          </div>
          <div id="secReview" className={`${ratingCaption == '' && 'hidden'} flex flex-col gap-2 `}>
            <label htmlFor="bio" className="label">Ulasan</label>
            <textarea onKeyUp={handleReviewKeyUp} value={review} onChange={handleChangeReview} name="review" id="review" rows={4} className="py-2.5 px-4 rounded-lg focus:border-2 focus:border-sky-400 outline-none border-2 boder-slate-50" placeholder="Bagaimana pendapatmu, setelah mempelajari Course ini?" title="Review Course" maxLength={255} />
            <span className={`${255- parseInt(review.length) < 10 && 'text-red-400'} text-xs text-right`} id="reviewCount">Tersisa : {inputCount}</span>
          </div>
          <div className="mt-8 flex gap-4 justify-center">
            <button onClick={handleClickClose} type="button" className="py-1.5 px-5 border-2 border-slate-400 text-slate-400 rounded-md  hover:bg-slate-400 hover:text-white text-sm">
              Nanti
            </button>
            <button id="btnSubmit" type="submit" className={`${review == '' && 'hidden'} py-2 px-5 bg-sky-400 rounded-md text-white hover:bg-sky-500 text-sm `}>
              Kirim
            </button>
          </div>
        </form>
      </div>
    </>


  )
}

export default ModalReview