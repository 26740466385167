import { UAParser } from 'ua-parser-js';
import { v4 as uuidv4 } from 'uuid'

//export const BASE_URL_API= 'http://localhost:8000/api/member';
//export const BASE_URL= 'http://localhost:8000';
export const BASE_URL_API= 'https://codingasikacademy.com/api/member';
export const BASE_URL= 'https://codingasikacademy.com';
export const mUser = JSON.parse(localStorage.getItem('mUser'));
export const mToken = localStorage.getItem('mToken');

export const getOrCreateDeviceId=()=>{
  let deviceId = localStorage.getItem('device_id')

  if(!deviceId){
    deviceId = uuidv4();
    localStorage.setItem('device_id', deviceId);
  }

  return deviceId
}

export const getUserAgent=()=>{
  const parser = new UAParser();
  const parsedData = parser.getResult();
  const {name : nameBroser, version : versionBrowser} = parsedData.browser
  const {name : nameOS, version : versionOS} = parsedData.os
  const useAgent = {
    browser : `${nameBroser} ${versionBrowser}`,
    os : `${nameOS} ${versionOS}`,
  }

  return useAgent
}

export function formatRupiah(number) {
  if(number){
    return number.toLocaleString('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0
    });
  }else{
    return '-'
  }
}
export const fetchData= async(url,options={})=>{
  const defaultHeaders = {
    'M-DEVICE-ID' : getOrCreateDeviceId(),
  };

  const combinedHeaders = {
    ...defaultHeaders,
    ...options.headers,  // Headers khusus akan menimpa default jika ada yang sama
  };

  const mergedOptions = {
    ...options,  // Menggabungkan semua options yang sudah di-passing
    headers: combinedHeaders,  // Set header yang sudah digabung
  };
  try {
    const response = await fetch(`${BASE_URL_API}/${url}`, mergedOptions);
    
    // Get the value of a specific header
    const mNewToken = response.headers.get('M-New-Token');
  
    if(mNewToken != null){
      localStorage.setItem('mToken',mNewToken);
    }

    
    const data = await response.json();
    if(response.status != 200){
      if(response.status == 401){
        window.location.href = '/login'
      }
    }
    if (!response.ok) {
      throw new Error(data.message);
    }
    return data;
  } catch (error) {
    throw error;
  }
}


 // Fungsi untuk menghitung selisih waktu antara dua tanggal
 export const calculateTimeDifference = (dateString) => {
  const currentDate = new Date();
  const pastDate = new Date(dateString);
  
  const years = currentDate.getFullYear() - pastDate.getFullYear();
  const months = currentDate.getMonth() - pastDate.getMonth();
  const days = currentDate.getDate() - pastDate.getDate();

  let totalYears = years;
  let totalMonths = months;
  let totalDays = days;

  // Jika hari negatif, pinjam bulan dari bulan sekarang
  if (totalDays < 0) {
    totalMonths -= 1;
    totalDays += new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();
  }

  // Jika bulan negatif, pinjam tahun dari tahun sekarang
  if (totalMonths < 0) {
    totalYears -= 1;
    totalMonths += 12;
  }

  if (totalYears > 0) {
    return `${totalYears} years ago`;
  } else if (totalMonths > 0) {
    return `${totalMonths} months ago`;
  } else {
    return `${totalDays} days ago`;
  }
};