import React, { useEffect, useState } from 'react'
import heroDashboard from '../assets/img/hero/hero-dashboard.png'
import { mUser, BASE_URL } from '../config/Config';
import { Helmet } from 'react-helmet';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import ModalProgress from '../components/ModalProgress';
import MemberCoursesStore from '../store/MemberCoursesStore';
import MemberHistoriesStore from '../store/MemberHistoriesStore';
import Loading from '../components/Loading';
import { Link } from 'react-router-dom';
import CourseDetailsStore from '../store/CourseDetailsStore';

const Home = () => {
  const { dataRequired,setDataRequired, setRating } = CourseDetailsStore();
  const { courses, fetchCourses,loadingScreen } = MemberCoursesStore();
  const { history, fetchHistory } = MemberHistoriesStore();

  const localizer = momentLocalizer(moment);

  useEffect(()=>{
    setDataRequired('')
    setRating(null)
  },[])

  useEffect(() => {
    if (mUser && courses.length == 0) {
      fetchCourses()
    }
  }, [mUser,courses])
  
  const [showModalHistory, setShowModalHistory] = useState(0)

  useEffect(() => {
    if (mUser && history.length == 0) {
      fetchHistory();
    }
  }, [mUser,history])

  const handleDetailHistory = () => {
    setShowModalHistory(1);
  }
  


  const events = history.map(history => ({
    title: 'Belajar',
    start: new Date(history.learn_at),
    end: new Date(history.learn_at)
  }));



  return (
    <div>
      <Helmet>
        <title>Home | Coding Asik Academy</title>
      </Helmet>
      {!loadingScreen && <Loading/>}
      <div className="flex gap-4 p-4 items-center justify-between lg:mt-8">
        <div className='w-3/4'>
          <h1 className="text-3xl mb-2 lg:text-5xl"><span className="font-semibold">Hallo, {mUser && mUser.first_name} </span><span id="firstName" /></h1>
          <p className="text-slate-500 lg:mt-4">Welcome back!! Semoga harimu menyenangkan</p>
        </div>
        <div className='w-1/4 flex justify-end'>
        <img className="w-16 h-16 border-2 border-sky-400 rounded-full md:w-20 md:h-20 lg:w-24 lg:h-24 md:border-4 object-cover object-center" src={mUser && mUser.picture} alt={mUser && mUser.first_name}  />
        </div>
      </div>

      <div className="flex flex-col gap-8 lg:flex-row lg:mt-12 lg:items-start">
        <div className="mt-10 lg:w-1/2 lg:bg-slate-50 lg:mt-0 lg:p-8 lg:rounded-lg flex flex-col gap-12">
          <div id="wrapperQuotes" className="lg:w-full bg-white p-4 rounded-lg relative">
            <button id="btnQuotes" type="button" className="absolute top-0 right-0 py-1.5 px-2 rounded-full bg-red-400 text-white -mt-2 -mr-2 flex justify-center items-center"><i className="fa-solid fa-close" /></button>
            <div className="flex flex-col gap-4 items-center justify-center relative">
              <img className="w-full" src={`${BASE_URL}/public/img/cover/course/cover_course_jago_php.png`} alt="Course Jago PHP" />
              <p className="bg-slate-100 rounded-lg p-4 text-center">Udah selesai belum nih materi html css dan javascriptnya? Jika udah yok lanjut belajar bahasa PHP. Tampilan yang kamu buat jadikan sebuah sistem atau web app. Buat detailnya? <a target="_blank" className="underline font-bold" title="Kepoin Seriesnya" href="https://codingasikacademy.com/course/jago-php">Klik disini</a></p>
            </div>
          </div>


          <div className="lg:w-full">
            <h3 className="font-semibold text-xl">Notifikasi</h3>

            <div className="mt-6 lg:mt-8 flex flex-col gap-4 lg:gap-4">
              <div className="rounded-lg bg-green-500 text-white p-4 flex gap-4 relative overflow-hidden lg:p-6">
                <span className="absolute top-0 left-0 w-2 h-full bg-slate-300" />
                <i className="fa-solid fa-circle-info" />
                <p className="-mt-1"><b>Update Course Jago Javascript &amp; Jago Web Design</b><br /> Ada 7 materi yang telah ditambahkan tentang javascript pro yaitu destructuring, spread operator, rest parameter, module, oop (class object property constructor static dan inheritence). Cek materi di tab course
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:flex lg:gap-8 lg:flex-col lg:w-1/2 lg:bg-slate-50 lg:p-8 lg:rounded-lg">
          <div className="lg:w-full">
            <h3 className="font-semibold text-xl">Course saya</h3>
            
            <div id="courseList" className="mt-4 lg:mt-8 flex flex-col gap-4">
              {
                courses == '' ? <div className="w-full flex gap-8">
                <div className="animate-pulse flex space-x-4 w-1/2">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 bg-slate-200 rounded" />
                    <div className="h-2 bg-slate-200 rounded" />
                    <div className="h-2 bg-slate-200 rounded" />
                  </div>
                </div>
                <div className="animate-pulse flex space-x-4 w-1/2">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 bg-slate-200 rounded" />
                    <div className="h-2 bg-slate-200 rounded" />
                    <div className="h-2 bg-slate-200 rounded" />
                  </div>
                </div>
              </div> :
                courses.map(item => (
                  <div key={item.id} className="rounded-lg bg-white px-4 py-8 flex gap-6 md:gap-8 md:px-8 lg:flex-wrap xl:flex-nowrap ">
                    <div className="flex flex-col items-center gap-4 justify-center lg:w-full xl:w-1/3">
                      <div className="relative">
                        <img className="w-20 h-20 p-2 rounded-full md:w-24 md:h-24 lg:w-28 lg:h-28" src={`${BASE_URL}/storage/admin/files/courses/${item.thumbnail}`} alt="JWD Logo" />
                        <span className="w-20 h-20 absolute top-0 border-4 border-sky-400 rounded-full md:w-24 md:h-24 lg:w-28 lg:h-28" />
                      </div>
                      <h4 className="text-2xl text-sky-500 font-semibold lg:text-3xl">{Math.round(item.progress)}%</h4>
                    </div>
                    <div className="lg:text-center lg:w-full xl:text-left xl:w-2/3">
                      <h4 className="font-semibold mb-4 lg:text-lg lg:mb-6">{item.course_name}</h4>
                      <div className="flex gap-6 text-sm text-slate-500 w-full lg:text-base lg:justify-center xl:justify-start">
                        <div className="flex flex-col gap-2 lg:gap-3 items-start">
                          <p>
                            <i className="mr-2 fa-solid fa-video" />
                            {item.total_lessons} Video
                          </p>
                          <p>
                            <i className="mr-2 fa-solid fa-file" />
                            {item.total_files} Files
                          </p>
                        </div>
                        <div className="flex flex-col gap-2 lg:gap-3 items-start">
                          <p>
                            <i className="mr-2 fa-solid fa-clock" />
                            {item.total_duration} Jam
                          </p>
                          <p>
                            <i className="mr-2 fa-solid fa-user" />
                            {item.total_members} Member
                          </p>
                        </div>
                      </div>
                      <div className="mt-6 text-sm lg:mt-8 flex lg:text-base lg:justify-center xl:justify-start">
                        <Link to={`/course/${item.course_slug}`} className="bg-sky-500 text-white py-2 px-4 rounded-lg hover:bg-sky-400">Lanjutkan</Link>
                      </div>
                    </div>
                  </div>
                ))
              }

            </div>
          </div>
          <div className="mt-12 lg:w-full lg:mt-0">
            <div className="flex justify-between">
              <h3 className="font-semibold text-xl">Riwayat belajar</h3>
              <button onClick={handleDetailHistory} className="py-1.5 px-4 rounded-full border border-sky-400 text-sky-400 hover:bg-sky-400 hover:text-white flex justify-center items-center gap-2 text-sm" data-bs-toggle="modal" data-bs-target="#modalProgress"><i className="fa-solid fa-search" /> Detail</button>
            </div>
            <div className="mt-4 lg:mt-8">
              <div id="learnHistory" className="bg-white rounded-lg" />
              <div className='w-full h-96 text-center'>
                <Calendar
                  localizer={localizer}
                  events={events}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: '100%' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalProgress history={history} showModalHistory={showModalHistory} setShowModalHistory={setShowModalHistory} />
    </div>
  )
}

export default Home