import React from 'react'
import {BASE_URL} from '../../../config/Config'
const TabTransaction = ({ transactions, handleTab, tab }) => {
  let no = 1;
  return (
    <li>
      <button data-target="transaction" onClick={() => handleTab('transaction')} className={`${!tab.transaction ? 'bg-white' : 'bg-sky-400 text-white'} tab absolute top-0 left-16 ml-6 inline-block  mb-2 shadow-md rounded-lg hover:bg-sky-400 hover:text-white outline-none`}>
        <div className="relative">
          <h2 className="px-4 py-2">Transaksi dll</h2>
        </div>
      </button>
      <div data-id="transaction" className={`${!tab.transaction && 'hidden'} tab-body mt-14 `}>
        <div className="flex flex-col overflow-x-auto bg-white w-full">
          <div className="w-full">
            <table className="w-full text-left text-sm font-light">
              <thead className=" font-medium border-b border-b-neutral-300">
                <tr>
                  <th scope="col" className="px-6 py-4">No</th>
                  <th scope="col" className="px-6 py-4">Course</th>
                  <th scope="col" className="px-6 py-4">Status</th>
                  <th scope="col" className="px-6 py-4">Invoice</th>
                  <th scope="col" className="px-6 py-4">Certificate</th>
                  <th scope="col" className="px-6 py-4">Tanggal Order</th>
                  <th scope="col" className="px-6 py-4">Kode Transaksi</th>
                  <th scope="col" className="px-6 py-4">Tanggal Bayar</th>
                </tr>
              </thead>
              <tbody id="bodyTansaction">
                {
                  transactions == '' ? <tr className="border-b border-b-neutral-300">
                  <td className="whitespace-nowrap px-6 py-4 font-medium" colSpan={7}>
                    <div className="w-full ">
                      <div className="animate-pulse flex space-x-4">
                        <div className="flex-1 space-y-6 py-1">
                          <div className="h-2 bg-slate-200 rounded" />
                          <div className="h-2 bg-slate-200 rounded" />
                          <div className="h-2 bg-slate-200 rounded" />
                        </div>
                      </div>
                    </div>
                  </td>
                </tr> :
                  
                  transactions.map(item => (
                    <tr key={item.id} className="border-b border-b-neutral-300">
                      <td className="whitespace-nowrap px-6 py-4 font-medium">{no++}</td>
                      <td className="whitespace-nowrap px-6 py-4">{item.course_name}</td>
                      <td className="whitespace-nowrap px-6 py-4"><span className=" px-2 py-1 rounded-lg text-xs bg-green-500 text-white">{item.transaction_status}</span></td>
                      <td className="whitespace-nowrap px-6 py-4 text-center"><a href={`${BASE_URL}/member/invoice/${item.transaction_code}`} target="_blank" className="px-4 py-2 mb-2 shadow-md rounded-lg bg-sky-400 hover:bg-sky-500 text-white text-xs" title="Print Invoice"><i className="fa-solid fa-print"></i></a></td>
                      <td className="whitespace-nowrap px-6 py-4 text-center"><a href={`${BASE_URL}/member/certificate/${item.transaction_code}`} target="_blank" className="px-4 py-2 mb-2 shadow-md rounded-lg bg-green-500 hover:bg-sky-500 text-white text-xs" title="Print Certificate"><i className="fa-solid fa-print"></i></a></td>
                      <td className="whitespace-nowrap px-6 py-4">{item.transaction_time}</td>
                      <td className="whitespace-nowrap px-6 py-4">{item.transaction_code}</td>
                      
                      <td className="whitespace-nowrap px-6 py-4">{item.paid_at}</td>

                    </tr>
                  ))
                }

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </li>
  )
}

export default TabTransaction