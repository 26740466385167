import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import Login from './pages/Login';
import { AuthProvider, AuthContext } from './context/AuthContext';
import Product from './pages/Product';
import ScrollToTop from './config/ScrollToTop';
import Info from './pages/Info';
import Review from './pages/Review';
import Profile from './pages/profile/Profile';
import { ToastContainer } from 'react-toastify';
import AuthCallback from './components/AuthCallback';
import ResetPassword from './pages/ResetPassword';
import ForgetPassword from './pages/ForgetPassword';
import Course from './pages/course/Course';

const PrivateRoute = ({ element }) => {
  const { auth } = React.useContext(AuthContext);
  return auth.mToken ? element : <Navigate to="/login" />;
};

const App=()=> {
  return (
    <AuthProvider>
      <Router>
        <ScrollToTop/>
        <Routes>
          <Route element={<Layout/>}>
            <Route path="/" element={<PrivateRoute element={<Home />} />} />
            <Route path="/home" element={<PrivateRoute element={<Home />} />} />
            <Route path="/product" element={<PrivateRoute element={<Product />} />} />
            <Route path="/review" element={<PrivateRoute element={<Review />} />} />
            <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
            <Route path="/info" element={<PrivateRoute element={<Info />} />} />
          </Route>
          <Route path="/course/:slug" element={<PrivateRoute element={<Course />} />} />
          <Route path='/login' element={<Login/>}/>
          <Route path='/reset-password' element={<ResetPassword/>}/>
          <Route path='/forget-password' element={<ForgetPassword/>}/>
          <Route path='/auth/callback' element={<AuthCallback/>}/>
        </Routes>
      </Router>
          <ToastContainer />
    </AuthProvider>
  );
}

export default App;
