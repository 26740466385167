import React from 'react';

const Pagination = ({ lastPage,currentPage, totalPages, onPageChange }) => {
  const handleClick = (page) => {
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5; // Maximum pages to show in pagination

    // Logic to determine which pages to show
    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    // Adjust startPage and endPage based on currentPage
    if (currentPage <= Math.ceil(maxPagesToShow / 2)) {
      startPage = 1;
      endPage = Math.min(totalPages, maxPagesToShow);
    } else if (currentPage >= totalPages - Math.floor(maxPagesToShow / 2)) {
      startPage = totalPages - maxPagesToShow + 1;
      endPage = totalPages;
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <p
        key={i}
        onClick={() => handleClick(i)}
        className={`w-8 h-8 flex justify-center items-center rounded-sm hover:bg-slate-200 cursor-pointer ${i === currentPage ? 'bg-slate-200' : ''}`}>{i}</p>
        );
      }
      return pageNumbers;
    };

  return (
    <div className="flex gap-2">
      <button
        onClick={() => handleClick(1)} disabled={currentPage === 1}
        className="w-8 h-8 flex justify-center items-center hover:bg-slate-200 rounded-sm"><i className="fa-solid fa-backward-step" /></button>
      <button
        onClick={() => handleClick(currentPage - 1)} disabled={currentPage === 1}
        className="w-8 h-8 flex justify-center items-center hover:bg-slate-200 rounded-sm"><i className="fa-solid fa-caret-left" /></button>
      <div className="flex gap-2">
        {renderPageNumbers()}
      </div>
      <button 
        onClick={() => handleClick(currentPage + 1)} disabled={currentPage === totalPages}
        className="w-8 h-8 flex justify-center items-center hover:bg-slate-200 rounded-sm"><i className="fa-solid fa-caret-right" /></button>
      <button 
        onClick={() => handleClick(lastPage)} disabled={currentPage === totalPages}
        className="w-8 h-8 flex justify-center items-center hover:bg-slate-200 rounded-sm"><i className="fa-solid fa-forward-step" /></button>
    </div>
  );
};

export default Pagination;
