import { create } from "zustand"
import { mUser,mToken,fetchData } from "../config/Config";

const ProvinceStore = create(set =>({
  provinces : [],
  loading : 0,
  fetchProvinces : async()=>{
    try {
      const response = await fetch(`https://codingasik.github.io/api-wilayah-indonesia/static/api/provinces.json`);
      const data = await response.json();
      set({ provinces: data, loading : 1});
    } catch (error) {
      console.log(error)
    }
  }
}))

export default ProvinceStore