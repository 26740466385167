import React, { useState } from 'react'
import Header from './Header'
import Menu from './Menu'
import { Outlet } from 'react-router-dom'

const Layout = () => {
  const [bar,setBar] = useState(1)
  const handleMinimaze=()=>{
    bar? setBar(0) : setBar(1)
  }
  return (
    <main id="pageHome" className="relative">
      <Header bar={bar}/>
      <Menu handleMinimaze={handleMinimaze} bar={bar}/>
      <div id="sectionContent" className={`${bar && 'lg:ml-64'} p-4 md-24 md:p-8 lg:bg-white md:m-4 md:ml-28 md:rounded-lg mb-24 md:mb-8`}>
      
        <Outlet/>
      </div>

      {/* Modal Progress */}
      <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="modalProgress" tabIndex={-1} aria-labelledby="modalProgress" aria-modal="true" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable relative w-auto pointer-events-none">
          <form className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
              <h5 className="text-xl font-medium leading-normal text-gray-800" id="modalProgressLabel">
                Detail Progress
              </h5>
              <button type="button" className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body relative p-4">
              <div className="overflow-x-auto">
                <div className="w-full ">
                  <table className="min-w-full text-center">
                    <thead className="border-b bg-gray-800">
                      <tr>
                        <th scope="col" className="text-sm font-medium text-white px-6 py-4">
                          #
                        </th>
                        <th scope="col" className="text-sm font-medium text-white px-6 py-4">
                          Tanggal
                        </th>
                        <th scope="col" className="text-sm font-medium text-white px-6 py-4">
                          Playlist
                        </th>
                        <th scope="col" className="text-sm font-medium text-white px-6 py-4">
                          Materi
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-white border-b">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">1</td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          2022-10-24 11:10:31
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          HTML Dasar
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          Pengenalan HTML
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
              <button type="button" className="py-1.5 px-8 mr-2 border-2 border-green-400 rounded-lg  text-green-400 hover:bg-green-500 hover:text-white" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* End Modal Progress */}
      <div id="loading" className="fixed top-0 left-0 w-full h-full bg-white flex justify-center items-center z-50 hidden">
        <div className="flex flex-col justify-center gap-4 items-center">
          <img className="w-44 md:w-48" src="../assets/img/logo-full.png" alt="Logo" />
          <div className="flex gap-2 items-center">
            <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 lg:w-8 lg:h-8 lg:border-4 rounded-full text-blue-300" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <p>Tunggu ...</p>
          </div>
        </div>
      </div>
    </main>

  )
}

export default Layout