import React, { useState } from 'react'

const ModalProgress = ({ history, showModalHistory, setShowModalHistory }) => {

  let [number, setNumber] = useState(1);

  
  return (
    <div className={`${!showModalHistory && 'hidden'} fixed top-0 left-0 w-full h-screen z-40 bg-black bg-opacity-25 flex justify-center items-center p-4`}>
      <div className="bg-white shadow-md p-4 rounded-md relative w-full lg:w-5/6 h-[600px] m-4">
        <form className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
            <h5 className="text-xl font-medium leading-normal text-gray-800" id="modalProgressLabel">
              Riwayat Belajar
            </h5>
            <button onClick={() => setShowModalHistory(0)} type="button" className="absolute top-0 right-0 w-8 h-8 flex justify-center items-center bg-slate-100 text-red-500 rounded-md shadow-md"><i className="fa-solid fa-close" /></button>
          </div>
          <div className="modal-body relative p-4">
            <div className="overflow-auto">
              <div className="w-full overflow-auto h-[450px] ">
                <table className="min-w-full text-center ">
                  <thead className="border-b bg-gray-800">
                    <tr>
                      <th scope="col" className="text-sm font-medium text-white px-6 py-4">
                        #
                      </th>
                      <th scope="col" className="text-sm font-medium text-white px-6 py-4">
                        Tanggal
                      </th>
                      <th scope="col" className="text-sm font-medium text-white px-6 py-4">
                        Course
                      </th>
                      <th scope="col" className="text-sm font-medium text-white px-6 py-4">
                        Playlist
                      </th>
                      <th scope="col" className="text-sm font-medium text-white px-6 py-4">
                        Materi
                      </th>
                    </tr>
                  </thead>
                  <tbody id="bodyHistory">
                    {
                      history.map(item => (
                        <tr key={item.id} className="bg-white border-b">
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{number++}</td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {item.learn_at}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {item.course_name}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {item.playlist_name}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {item.lesson_name}
                          </td>
                        </tr>
                      ))
                    }

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

  )
}

export default ModalProgress