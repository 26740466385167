import { create } from "zustand"
import { mUser,mToken,fetchData } from "../config/Config";

const CourseDetailsStore = create(set =>({
  playlists : [],
  lessons : [],
  rating : null,
  dataRequired : '',
  loading : 0,
  setDataRequired : (val) => set({dataRequired : val}),
  setRating : (val) => set({rating : val}),
  fetchCourseDetails : async(slug,formData)=>{
    try {
      const data = await fetchData(`course/${slug}/details`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${mToken}`,
          'Content-Type': 'application/json',
        },
        body : JSON.stringify(formData)
      });
      set({
        playlists: data.data.playlists,
        lessons: data.data.lessons,
        rating: data.data.rating,
        dataRequired: data.data.dataRequired,
        loading: 1,
      });
    } catch (error) {
      console.log(error);
    }
  }
}))

export default CourseDetailsStore
