import React from 'react'
import { BASE_URL } from '../../../config/Config'
import checkListImage from '../../../assets/img/etc/checklist-materi.png'
import progressImage from '../../../assets/img/etc/progress-belajar.png'
const ModalInfo = ({isShowModalInfo, setIsShowModalInfo}) => {

  return (
    <div className={`${isShowModalInfo == 0 && 'hidden'} fixed top-0 left-0 w-full h-screen z-40 bg-black bg-opacity-25 flex justify-center items-center p-4`} >
      <div className="relative w-full md:w-3/6">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
            <h5 className="text-xl font-medium leading-normal text-gray-800" id="modalInfoLabel">
              Informasi Tentang Course
            </h5>
            <button onClick={() => setIsShowModalInfo(0)} type="button" className="absolute top-0 right-0 w-8 h-8 flex justify-center items-center bg-slate-100 text-red-500 rounded-md shadow-md"><i className="fa-solid fa-close" /></button>
          </div>
          <div className="modal-body relative p-4 md:p-8 overflow-auto h-[500px]">
            <div>
              <h4 className="font-semibold">A. Proses Belajar</h4>
              <p className="mt-4 mb-2">Sistem pembelajaran di Course ini adalah video pembelajaran yang mana memantapkan pemahaman basic web programming yaitu belajar html, css, dan javascript. Tujuan dari Course ini adalah bisa membuat Web Design statis. Hal yang perlu diperhatikan saat mempelajari materi di Course ini :</p>
              <ul className="list-decimal ml-6">
                <li>
                  Melakukan checklist di setiap tab materi, jika merasa telah memahami. Ini untuk mengukur sejauh mana pemahamanmu terhadap materi. <br />
                  <img className="w-full my-4 rounded-lg" src={checkListImage} alt="Checkist Materi" />
                </li>
                <li>
                  Progress akan bertambah setelah melakukan checklist di tab materi <br />
                  <img className="my-4 rounded-lg" src={progressImage}alt="Progress Belajar" />
                </li>
                <li>Jika ada materi yang belum bisa dipahami dan perlu ditanyakan, sharingkan saja di grup Discord. Coding Asik dan member lainnya akan saling membantu. Atau jika ada trouble di website (Error dll) bisa langsung kabarkan Coding Asik via whatsapp, agar bisa secepatnya ditangani.</li>
                <li>Terakhir, basic ini penting dan utama, maka belajarlah perlahan dan mengutamakan "Paham" dibandingkan "Cepat menyelesaikan materi". Ingat, kamu sudah rela untuk menginvest di Course ini, maka belajarlah konsisten. Semangat</li>
              </ul>
            </div>
            <div className="mt-8">
              <h4 className="font-semibold">B. Update materi</h4>
              <p className="mt-4 mb-2">Untuk mendapatkan update materi terbaru dan materi javascript, maka kamu harus memenuhi syarat ini:</p>
              <ul className="list-decimal ml-6">
                <li>Telah melengkapi semua Profil</li>
                <li>Telah melakukan proses belajar &gt; 30% dengan cara checklist di setiap tab materi. Ini dibuat agar kamu mempelajari materi secara urut dan terstruktur dari dasar.</li>
                <li>Melakukan review Course ini setelah mempelajarinya. Berikan tanggapanmu tentang cara penyampaian materi, kelengkapan materi dll. Ini akan meyakinkan dan membantu teman lainnya yang ingin belajar web programming dari dasar dengan Course ini.</li>
                <li>Ketika ada playlist atau materi baru, akan mempunyai icon <i className="fa-solid fa-circle-plus text-yellow-400" />. Tapi jika diplaylist ada <i className="fa-solid fa-circle-plus text-yellow-400" /> tapi materi tidak ada yang baru, berarti kamu harus melengkapi point 1-3 diatas. Dan note: setiap materi baru akan bedampak pada jumlah prosentase progress belajar.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  )
}

export default ModalInfo