import { create } from "zustand"
import { mUser,mToken,fetchData } from "../config/Config";

const MemberTransactionStore = create(set =>({
  transactions : [],

  fetchTransactions : async()=>{
    try {
      const data = await fetchData(`member/${mUser.username}/transactions`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${mToken}`,
        }
      });
      set({ transactions: data.data});
    } catch (error) {
      console.log(error)
    }
  }
}))

export default MemberTransactionStore