import React from 'react'
import logoFull from '../assets/img/brand/logo-full.png'
const Loading = () => {
  return (
    <div id="loading" className="fixed top-0 left-0 w-full h-full bg-white flex justify-center items-center z-50 ">
      <div className="flex flex-col justify-center gap-4 items-center">
        <img className="w-44 md:w-48" src={logoFull} alt="Logo" />
        <div className="flex gap-2 items-center">
          <div className="flex items-center justify-center">
            <button type="button" className="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm d text-black  hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed" disabled>
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} />
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
              </svg>
              Tunggu...
            </button>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Loading