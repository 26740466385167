import { create } from "zustand"
import { mUser,mToken,fetchData } from "../config/Config";

const MemberCoursesStore = create(set =>({
  courses : [],
  loadingScreen : 0,
  fetchCourses : async()=>{
    try {
      const data = await fetchData(`member/${mUser.username}/courses`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${mToken}`,
        }
      });
      set({ courses: data.data, loadingScreen : 1});
    } catch (error) {
      console.log(error)
    }
  }
}))

export default MemberCoursesStore