import { create } from "zustand"
import { mUser,mToken,fetchData } from "../config/Config";

const CityStore = create(set =>({
  cities : [],

  fetchCities : async(idProvince)=>{
    try {
      const response = await fetch(`https://codingasik.github.io/api-wilayah-indonesia/static/api/regencies/${idProvince}.json`);
      const data = await response.json();
      set({ cities: data});
    } catch (error) {
      console.log(error)
    }
  }
}))

export default CityStore