import React, { useState, useRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const ImageUploader = ({loadingDirect, setImage, onImageUpload,handleSubmit }) => {
    const [src, setSrc] = useState(null); // Original image source
    const [croppedImage, setCroppedImage] = useState(null); // Cropped image preview
    const [showCropper, setShowCropper] = useState(false); // Control cropper visibility
    const cropperRef = useRef(null);
    
    // Handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file.size > 2 * 1024 * 1024) { // 2MB max size
            alert('File terlalu besar! Maksimal ukuran 2MB.');
            return;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
            setSrc(reader.result); // Set image source for Cropper
            setShowCropper(true); // Show cropper when a new image is uploaded
        };
        reader.readAsDataURL(file);
    };

    // Handle crop button click
    const handleCrop = (e) => {
        e.preventDefault();
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            const croppedCanvas = cropper.getCroppedCanvas();
            if (croppedCanvas) {
                compressImage(croppedCanvas, 0.9); // Start compression with 90% quality
            }
        }
    };

    const compressImage = (canvas, quality) => {
        canvas.toBlob((blob) => {
            if (blob.size <= 300 * 1024) { // 300KB max size
                setCroppedImage(URL.createObjectURL(blob)); // Show preview
                onImageUpload(blob); // Send to parent
                setImage(blob);
                setShowCropper(false); // Hide cropper
            } else if (quality > 0.1) { // Reduce quality if still too large
                compressImage(canvas, quality - 0.1);
            } else {
                alert('Gambar tidak bisa dikompres lebih kecil dari 300KB.'); 
            }
        }, 'image/jpeg', quality); // Adjust quality
    };

    return (
        <form onSubmit={handleSubmit} encType="multipart/form-data" className="relative mt-4">

            <div className='flex flex-col gap-2 w-full pr-24 items-start'>
                <h4 className="mb-4 font-semibold">Upload Langsung</h4>
                <input type="file" accept="image/*" onChange={handleFileChange} />
                {showCropper && src && (
                    <div>
                        <div className='mt-4'>

                            <Cropper
                                src={src}
                                style={{ height: 300, width: '100%' }}
                                initialAspectRatio={1}
                                aspectRatio={1}
                                guides={false}
                                viewMode={2}
                                background={false}
                                responsive={true}
                                autoCropArea={1}
                                checkOrientation={false}
                                ref={cropperRef}
                            />
                            <button className="mt-4 inline-block px-3 bg-green-400 whitespace-nowrap text-white py-1.5 rounded-md" type="button" onClick={handleCrop}>Crop Image</button>
                        </div>

                    </div>

                )}
                {croppedImage && (
                    <div>
                        <div className="flex flex-col items-center gap-2 w-20 absolute top-0 right-0">
                            <img className="w-20 h-20 border border-slate-300  object-cover object-center rounded-full" src={croppedImage} alt="Cropped Preview" style={{ maxWidth: '100%' }} />
                            <h4 className="text-sm text-slate-400">Preview</h4>
                        </div>
                        <div id="sectionUploadBtn" className={` mt-10 border-t-2 border-t-slate-200 pt-4 flex justify-end`}>
                            <button id="btnUploadPhoto" type="submit" className="bg-red-400 py-2 px-4 rounded-md outline-none hover:bg-red-500 text-white"><i className="fa-solid fa-upload mr-2" />{loadingDirect == 0 ? 'Upload Foto' : 'Uploading...'}</button>
                        </div>
                    </div>
                )}
            </div>
        </form>
    );
};

export default ImageUploader;
