import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import DetailCourse from './components/DetailCourse'
import MateriCourse from './components/MateriCourse'
import MemberCoursesStore from '../../store/MemberCoursesStore'
import CourseDetailsStore from '../../store/CourseDetailsStore'
import { mUser } from '../../config/Config'
import MemberHistoriesStore from '../../store/MemberHistoriesStore'
import Loading from '../../components/Loading'
import ModalInfo from './components/ModalInfo'

const Course = () => {
  const { slug } = useParams('slug')
  const { courses, fetchCourses, loadingScreen } = MemberCoursesStore();
  const { playlists, lessons, rating, dataRequired,setDataRequired, loading, fetchCourseDetails } = CourseDetailsStore();
  const { history, fetchHistory, loadingHistory } = MemberHistoriesStore();

  const [selectedCourse, setSelectedCourse] = useState({});
  const [lessonNow, setLessonNow] = useState({})



  useEffect(() => {
    if (loadingScreen) {
      const course = courses.find(course => course.course_slug === slug);
      setSelectedCourse(course)

    } else {
      fetchCourses()
    }
  }, [loadingScreen, courses, fetchCourses])


  useEffect(() => {
    if (Object.entries(selectedCourse).length != 0) {
      if (mUser.username != '' || selectedCourse.id != '' || selectedCourse.progress != '') {
        fetchCourseDetails(slug, {
          username: mUser.username,
          id: selectedCourse.id,
          progress: selectedCourse.progress
        })
      }

    }
  }, [selectedCourse])


  useEffect(() => {
    if (loadingHistory == 1) {
      if (Object.entries(selectedCourse).length != 0 && lessons.length != 0 && history.length != 0) {
        const getHistoryCourse = history.filter(list => list.course_id == selectedCourse.id)
        if (getHistoryCourse.length != 0) {
          const getDataLesson = lessons.filter(item => item.id == getHistoryCourse[0].lesson_id)
          setLessonNow(getDataLesson[0])
        } else {
          setLessonNow(lessons[0])
        }
      }

    } else {
      fetchHistory()
    }
  }, [loadingHistory, selectedCourse, lessons, history, fetchHistory])

  const handleNextLesson = () => {
    const index = lessons.findIndex(item => item.id === lessonNow.id)
    if (index !== -1) {
      if (index + 1 < lessons.length) {
        const nextObject = lessons[index + 1];
        setLessonNow(nextObject)
      } else {
        const nextObject = lessons[0];
        setLessonNow(nextObject)
      }
    }
  }
  const handlePreviousLesson = () => {
    const index = lessons.findIndex(item => item.id === lessonNow.id)
    if (index !== -1) {
      if (index - 1 > 0) {
        const previousObject = lessons[index - 1];
        setLessonNow(previousObject)
      } else {
        const previousObject = lessons[lessons.length - 1];
        setLessonNow(previousObject)
      }
    }
  }

  const [isShowModalInfo, setIsShowModalInfo] = useState(0)


  return (
    <main id="pageCourse" className="bg-gray-100">
      {!loading && <Loading />}
      <input type="hidden" id="courseSlug" defaultValue="<?= $slug;?>" />
      <div className="w-full h-24 relative flex justify-between items-center bg-white px-8">
        <Link to="/" className="border border-slate-400 py-2 px-2 md:px-3 rounded-lg text-slate-400 hover:bg-slate-500 hover:text-white flex justify-center items-center gap-2">
          <i className="fa-solid fa-arrow-left" />
          <span className="hidden md:inline-block">Kembali</span>
        </Link>
        <div className="text-center">
          <h2 className="text-xl font-semibold text-sky-400 capitalize" >{selectedCourse && selectedCourse.course_name}</h2>
          <div className="mt-2">
            <div className="flex gap-2">
              <p className="text-sm">
                <i className="text-slate-400 fa-solid fa-video" /> <span>{selectedCourse && selectedCourse.total_lessons} Video</span>
              </p>
              <p className="text-sm">
                <i className="text-slate-400 fa-solid fa-clock" /> <span>{selectedCourse && selectedCourse.total_duration}</span>
              </p>
            </div>
          </div>
        </div>
        <button onClick={()=>setIsShowModalInfo(1)} className="border border-sky-400 py-2 px-3 rounded-lg text-sky-400 hover:bg-sky-500 hover:text-white flex justify-center items-center gap-2" data-bs-toggle="modal" data-bs-target="#modalInfo">
          <i className="fa-solid fa-info" />
          <span className="hidden md:inline-block">Informasi</span>
        </button>
      </div>
      <ModalInfo isShowModalInfo={isShowModalInfo} setIsShowModalInfo={setIsShowModalInfo}/>
      <div className="flex flex-col md:flex-row w-full items-start md:px-4 lg:px-8  xl:px-16  mx-auto relative">{/* xl:bg-red-300 lg:bg-yellow-300  md:bg-green-400*/}
        <div className="w-full">
          <div className="w-full md:static sticky z-20 top-0 md:mt-8 ">
            <iframe className="w-full aspect-video bg-slate-300" src={lessonNow && lessonNow.lesson_url} title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
          </div>
          <div className="p-4 md:px-0">
            <div className="flex flex-col gap-4 md:flex-row md:justify-between md:items-center mt-4 mb-8">
              <h1 className="text-xl font-semibold border-b border-b-slate-200 pb-4 md:border-none md:pb-0">{lessonNow && lessonNow.lesson_name}</h1>
              <div className="flex gap-4 justify-between w-full md:w-56 ">
                <button type="button" className="border border-slate-400 p-1.5 rounded-lg text-slate-400 hover:bg-slate-500 hover:text-white flex justify-center items-center gap-2 text-sm" onClick={handlePreviousLesson}>Sebelumnya</button>
                <button type="button" className="border bg-sky-400 text-white p-2 rounded-lg hover:bg-sky-500 hover:text-white flex justify-center items-center gap-2 text-sm" onClick={handleNextLesson}>Selanjutnya</button>
              </div>
            </div>
            <DetailCourse slug={slug} selectedCourse={selectedCourse} lessonNow={lessonNow} />
          </div>
        </div>
        <MateriCourse rating={rating} setDataRequired={setDataRequired} selectedCourse={selectedCourse} lessonNow={lessonNow} setLessonNow={setLessonNow} playlists={playlists} lessons={lessons} dataRequired={dataRequired}/>

      </div>
      {/* Modal Informasi */}
      <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="modalInfo" tabIndex={-1} aria-labelledby="modalInfo" aria-modal="true" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable relative w-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
              <h5 className="text-xl font-medium leading-normal text-gray-800" id="modalInfoLabel">
                Informasi Tentang Course
              </h5>
              <button type="button" className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body relative p-4 md:p-8">
              <div>
                <h4 className="font-semibold">A. Proses Belajar</h4>
                <p className="mt-4 mb-2">Sistem pembelajaran di Course ini adalah video pembelajaran yang mana memantapkan pemahaman basic web programming yaitu belajar html, css, dan javascript. Tujuan dari Course ini adalah bisa membuat Web Design statis. Hal yang perlu diperhatikan saat mempelajari materi di Course ini :</p>
                <ul className="list-decimal ml-6">
                  <li>
                    Melakukan checklist di setiap tab materi, jika merasa telah memahami. Ini untuk mengukur sejauh mana pemahamanmu terhadap materi. <br />
                    <img className="w-full my-4 rounded-lg" src="<?= base_url('public/assets/img/items/checklist-materi.png');?>" alt="Checkist Materi" />
                  </li>
                  <li>
                    Progress akan bertambah setelah melakukan checklist di tab materi <br />
                    <img className="my-4 rounded-lg" src="<?= base_url('public/assets/img/items/progress-belajar.png');?>" alt="Progress Belajar" />
                  </li>
                  <li>Jika ada materi yang belum bisa dipahami dan perlu ditanyakan, sharingkan saja di grup Discord. Coding Asik dan member lainnya akan saling membantu. Atau jika ada trouble di website (Error dll) bisa langsung kabarkan Coding Asik via whatsapp, agar bisa secepatnya ditangani.</li>
                  <li>Terakhir, basic ini penting dan utama, maka belajarlah perlahan dan mengutamakan "Paham" dibandingkan "Cepat menyelesaikan materi". Ingat, kamu sudah rela untuk menginvest di Course ini, maka belajarlah konsisten. Semangat</li>
                </ul>
              </div>
              <div className="mt-8">
                <h4 className="font-semibold">B. Update materi</h4>
                <p className="mt-4 mb-2">Untuk mendapatkan update materi terbaru dan materi javascript, maka kamu harus memenuhi syarat ini:</p>
                <ul className="list-decimal ml-6">
                  <li>Telah melengkapi semua Profil</li>
                  <li>Telah melakukan proses belajar &gt; 30% dengan cara checklist di setiap tab materi. Ini dibuat agar kamu mempelajari materi secara urut dan terstruktur dari dasar.</li>
                  <li>Melakukan review Course ini setelah mempelajarinya. Berikan tanggapanmu tentang cara penyampaian materi, kelengkapan materi dll. Ini akan meyakinkan dan membantu teman lainnya yang ingin belajar web programming dari dasar dengan Course ini.</li>
                  <li>Ketika ada playlist atau materi baru, akan mempunyai icon <i className="fa-solid fa-circle-plus text-yellow-400" />. Tapi jika diplaylist ada <i className="fa-solid fa-circle-plus text-yellow-400" /> tapi materi tidak ada yang baru, berarti kamu harus melengkapi point 1-3 diatas. Dan note: setiap materi baru akan bedampak pada jumlah prosentase progress belajar.</li>
                </ul>
              </div>
            </div>
            <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
              <button type="button" className="py-1.5 px-8 mr-2 border-2 border-green-400 rounded-lg  text-green-400 hover:bg-green-500 hover:text-white" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*End Modal Informasi */}
    </main>

  )
}

export default Course