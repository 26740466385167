import React, { useEffect, useState } from 'react'
import TabBio from './components/TabBio';
import TabTransaction from './components/TabTransaction';
import TabReview from './components/TabReview';
import MemberReviewStore from '../../store/MemberReviewStore';
import MemberTransactionStore from '../../store/MemberTransactionStore';
import ModalPassword from './components/ModalPassword';
import MemberStore from '../../store/MemberStore'
import ModalUpload from './components/ModalUpload';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading';
import { Helmet } from 'react-helmet';
const Profile = () => {

  const {logout,isLoggedOut} = React.useContext(AuthContext);
  const navigate = useNavigate()
  
  const { member, loading: loadingMember, fetchMember } = MemberStore()
  const { reviews, fetchReviews } = MemberReviewStore()
  const { transactions, fetchTransactions } = MemberTransactionStore()
  const [isLogout, setIsLogout] = useState(0)
  const [tab, setTab] = useState({
    bio: 1,
    transaction: 0,
    review: 0
  })

  useEffect(() => {
    fetchMember()
  }, [])

  const [showModalPassword, setShowModalPassword] = useState(0)
  const [showModalUpload, setShowModalUpload] = useState(0)


  const handleTab = (val) => {
    if (val == 'bio') {
      setTab({
        bio: 1,
        transaction: 0,
        review: 0
      })
    } else if (val == 'transaction') {
      setTab({
        bio: 0,
        transaction: 1,
        review: 0
      })
      fetchTransactions()
    } else if (val == 'review') {
      setTab({
        bio: 0,
        transaction: 0,
        review: 1
      })
      fetchReviews()
    }
    
  }

  const handleLogout = async(e) =>{
    e.preventDefault()
    setIsLogout(1)
    await logout()
    if(isLoggedOut){
      setIsLogout(0)
      navigate('/login')
    }
  }

  return (
    <div>
      <Helmet>
        <title>Profile | Coding Asik Academy</title>
      </Helmet>
      {!loadingMember && <Loading/>}
      <ModalPassword showModalPassword={showModalPassword} setShowModalPassword={setShowModalPassword}/>
      <ModalUpload showModalUpload={showModalUpload} setShowModalUpload={setShowModalUpload}/>
      <h3 className="font-semibold text-2xl text-center mt-8 mb-16">Profile</h3>
      <div className="lg:flex lg:gap-8 lg:mt-12 lg:items-start">
        <div className="w-full lg:w-2/6 shadow-md p-4 rounded-md border-t-2">
          <div className="text-center">
            <div className="w-full flex justify-center mb-2">
              <img className="w-24 h-24 object-cover rounded-full p-1 border-2 border-sky-400" src={member ? member.picture : ''} alt={`${member ? member.first_name : ''} ${member ? member.last_name : ''} `} />
            </div>
            <h1 className="font-bold text-lg">{member ? member.first_name : ''} {member ? member.last_name : ''}</h1>
            <p>{member ? member.job : ''}</p>
          </div>
          <hr className="my-4" />
          <div className="flex flex-col gap-4">
            <button onClick={handleLogout} className="p-2 border border-red-400 text-red-400 rounded-lg w-full hover:text-white hover:bg-red-500 flex flex-col gap-2 items-center justify-center">{!isLogout ? 'Logout' : 'Proses logout..'}</button>
            <button onClick={()=>setShowModalPassword(1)} className="p-2 border border-sky-400 text-sky-400 rounded-lg w-full hover:text-white hover:bg-sky-500" data-bs-toggle="modal" data-bs-target="#exampleModalCenteredScrollable">Ubah password</button>
            <button onClick={()=>setShowModalUpload(1)} className="p-2 border border-green-400 text-green-400 rounded-lg w-full hover:text-white hover:bg-green-500">Ubah Foto Profile</button>
          </div>
        </div>
        <div className="mt-8 lg:w-2/3 md:p-8 md:rounded-lg md:bg-slate-50 md:mt-4 lg:mt-0 relative">
          <ul className="relative m-0 list-none overflow-hidden p-0 transition-[height] duration-200 ease-in-out">
            <TabBio member={member} loadingMember={loadingMember} handleTab={handleTab} tab={tab} />
            <TabTransaction transactions={transactions} handleTab={handleTab} tab={tab}/>
            <TabReview reviews={reviews} handleTab={handleTab} tab={tab}/>

          </ul>
        </div>
      </div>
    </div>

  )
}

export default Profile