import React, { useEffect, useState } from 'react'
import { mUser, mToken, fetchData } from '../../../config/Config'
import Notification , { notifySuccess, notifyError }  from '../../../config/Notification'
import ProvinceStore from '../../../store/ProvinceStore'
import CityStore from '../../../store/CityStore'


const TabBio = ({member, loadingMember, handleTab, tab }) => {
  
  const { provinces, fetchProvinces } = ProvinceStore()
  const { cities, fetchCities } = CityStore()

  const [formData, setFormData] = useState({})
  const [skills, setSkills] = useState([])

  useEffect(() => {
    fetchProvinces()
    if (loadingMember) {
      fetchCities(member.province_id)
      setFormData(member)

      if(member.skill != null){
        const newArray = member.skill.split(',').map(item => item.trim()); // Membagi string berdasarkan koma dan menghapus spasi tambahan
        setSkills(newArray);
      }
      

    }
    /* }
    getData() */
  }, [loadingMember])



  const [selectedValues, setSelectedValues] = useState([]);

  const handleSelectedSkill = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
    setSkills(selectedOptions)
    //setSelectedValues(selectedOptions);
    setFormData({
      ...formData,
      skill: selectedOptions.join(', ')
    })
  }

  const handleCitySelected = e => {
    e.preventDefault()
    const selected = e.target.selectedIndex;
    const selectedText = e.target.options[selected].text;
    const selectedValue = e.target.value;
    setFormData({
      ...formData,
      city_id: selectedValue,
      city_name: selectedText
    })
  }

  const handleProvinceSelected = e => {
    e.preventDefault()
    const selected = e.target.selectedIndex;
    const selectedText = e.target.options[selected].text;
    const selectedValue = e.target.value;
    setFormData({
      ...formData,
      province_id: selectedValue,
      province_name: selectedText,
      city_id: '',
      city_name: ''
    })
    fetchCities(selectedValue)
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (formData.first_name == '' || formData.gender == '' || formData.dob == '' || formData.dob == '') {
      notifyError('Data tidak boleh kosong')
    } else {
      sendMemberUpdate()
    }
  }  
  
  const sendMemberUpdate = async()=>{
    try {
      const data = await fetchData(`member/${mUser.username}/store`,{
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${mToken}`,
          'Content-Type' : 'application/json'
        },
        body : JSON.stringify(formData)
      });
      if(data.success == true){
        notifySuccess(data.message)
        let storedData = localStorage.getItem('mUser');
        if (storedData) {

          let dataObject = JSON.parse(storedData);
          dataObject.first_name = formData.first_name;
          dataObject.last_name = formData.last_name;
          localStorage.setItem('mUser', JSON.stringify(dataObject));

        }
      }else{
        notifyError(data.message)
      }
    } catch (error) {
      notifyError(error.message)
    }

  }

  return (
    <li>
      <button data-target="bio" onClick={() => handleTab('bio')} className={`${!tab.bio ? 'bg-white' : 'bg-sky-400 text-white'} tab absolute top-0 left-0 inline-block px-4 py-2 mb-2 shadow-md rounded-lg hover:bg-sky-400 hover:text-white outline-none`} >
        <h2>Profile</h2>
      </button>
      <div data-id="bio" className={`${!tab.bio && 'hidden'} tab-body mt-14 `} >
        <h4 className="mb-4 text-center w-full mt-12 md:mt-0 bg-green-400 p-2 rounded-md text-white">Pastikan data terisi semua. Opsional bisa kosong</h4>
        <form onSubmit={handleSubmit} className="flex flex-col gap-8 mt-8">
          <div className="flex flex-col lg:flex-row gap-4">
            <div className="flex flex-col gap-2 w-full lg:w-1/2">
              <label className='text-slate-500' htmlFor="firstName">Nama Depan</label>
              <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 capitalize"
                type="text"
                name="firstName"
                placeholder="Input Nama Depan"
                title="Input Nama Depan"
                value={formData && formData.first_name}
                onChange={e => {
                  setFormData({
                    ...formData,
                    first_name: e.target.value
                  })
                }}
                required />
            </div>
            <div className="flex flex-col gap-2 w-full lg:w-1/2">
              <label className='text-slate-500' htmlFor="lastName">Nama Belakang <span className='text-sm text-green-300'>*opsional</span></label>
              <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 capitalize"
                type="text"
                name="lastName"
                value={formData && formData.last_name}
                onChange={e => {
                  setFormData({
                    ...formData,
                    last_name: e.target.value
                  })
                }}
                placeholder="Input Nama Depan"
                title="Input Nama Depan"
                />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-4">
            <div className="flex flex-col gap-2 w-full lg:w-1/2">
              <label className='text-slate-500' htmlFor="gender">Jenis Kelamin</label>
              <div className="flex gap-2">
                <input type="radio" name="gender" id="male" value="m" required checked={formData && formData.gender === 'm'} onChange={e => setFormData({ ...formData, gender: e.target.value })} /> Laki-laki
                <input type="radio" name="gender" id="female" value="f" required checked={formData && formData.gender === 'f'} onChange={e => setFormData({ ...formData, gender: e.target.value })} /> Perempuan
              </div>
            </div>
            <div className="flex flex-col gap-2 w-full lg:w-1/2">
              <label className='text-slate-500' htmlFor="dob">Tanggal Lahir</label>
              <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 "
                type="date"
                name="dob"
                value={formData && formData.dob}
                onChange={e => {
                  setFormData({
                    ...formData,
                    dob: e.target.value
                  })
                }}
                title="Input Tanggal Lahir"
                required />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-4">
            <div className="flex flex-col gap-2 w-full lg:w-1/2">
              <label className='text-slate-500' htmlFor="province">Provinsi</label>
              <select className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 capitalize"
                type="text"
                name="province"
                title="Pilih Provinsi"
                value={formData && formData.province_id}
                onChange={handleProvinceSelected}
                required >
                <option value="">--Pilih Provinsi--</option>
                {
                  provinces.map(item => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))
                }
              </select>
            </div>
            <div className="flex flex-col gap-2 w-full lg:w-1/2">
              <label className='text-slate-500' htmlFor="city">Kota/Kabupaten </label>
              <select className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 capitalize"
                type="text"
                name="city"
                title="Pilih Kota/Kabupaten"
                value={formData && formData.city_id}
                onChange={handleCitySelected}

                required >
                <option value="">--Pilih Kota/Kabupaten--</option>
                {
                  cities.map(item => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))
                }
              </select>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-4">
            <div className="flex flex-col gap-2 w-full lg:w-1/2">
              <label className='text-slate-500' htmlFor="whatsapp">Whatsapp</label>
              <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 capitalize"
                type="text"
                name="whatsapp"
                value={formData && formData.whatsapp}
                onChange={e => {
                  setFormData({
                    ...formData,
                    whatsapp: e.target.value
                  })
                }}
                placeholder="Input Whatsapp"
                title="Input Whatsapp"
                required />
            </div>
            <div className="flex flex-col gap-2 w-full lg:w-1/2">
              <label className='text-slate-500' htmlFor="instagram">Instagram <span className='text-sm text-green-300'>*opsional</span></label>
              <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 capitalize"
                type="text"
                name="instagram"
                value={formData && formData.instagram}
                onChange={e => {
                  setFormData({
                    ...formData,
                    instagram: e.target.value
                  })
                }}
                placeholder="Input Instagram"
                title="Input Instagram" />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-4">
            <div className='flex flex-col gap-4 w-full lg:w-1/2'>
              <div className="flex flex-col gap-2 w-full ">
                <label className='text-slate-500' htmlFor="job">Pekerjaan</label>
                <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 capitalize"
                  type="text"
                  name="job"
                  value={formData && formData.job}
                  onChange={e => {
                    setFormData({
                      ...formData,
                      job: e.target.value
                    })
                  }}
                  placeholder="Input Pekerjaan"
                  title="Input Pekerjaan"
                  required />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <label className='text-slate-500' htmlFor="level">Tentang web programming</label>
                <select class="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 capitalize" name="level" id="level" title="Pemahaman Web Programming"
                  value={formData && formData.level}
                  onChange={e => {
                    setFormData({
                      ...formData,
                      level: e.target.value
                    })
                  }}>
                  <option value="">--Sejauh mana pemahamanmu?--</option>
                  <option value="pemula">Baru berniat mempelajari Web Pro</option>
                  <option value="basic-web">Masih basic awal, ingin mendalami</option>
                  <option value="basic-server">Sedang/ingin mempelajari bahasa server (php dll)</option>
                  <option value="framework-frontend">Sedang mempelajari framework Frontend (React, Vue dll)</option>
                  <option value="framework-backend">Sedang mempelajari framework Backend (Laravel, CI dll)</option>
                  <option value="frontend-dev">Sudah menguasai Frontend</option>
                  <option value="backend-dev">Sudah menguasai Backend</option>
                  <option value="fullstack-dev">Sudah menguasai Full stack</option>
                </select>
              </div>
            </div>
            <div className="flex flex-col gap-2 w-full lg:w-1/2">
              <label className='text-slate-500' htmlFor="skill">Skill <span className='text-sm text-green-300'>*opsional</span></label>
              <select name="optionBahasa" id="optionBahasa" class="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 capitalize" multiple
                onChange={handleSelectedSkill}
                value={skills}>
                <option class="mb-2" value="pemula">Pemula</option>
                <option class="mb-2" value="html">HTML</option>
                <option class="mb-2" value="css">CSS</option>
                <option class="mb-2" value="javascript">Javascript</option>
                <option class="mb-2" value="bootstrap">Bootstrap</option>
                <option class="mb-2" value="tailwind">Tailwind</option>
                <option class="mb-2" value="php">PHP</option>
                <option class="mb-2" value="laravel">Laravel</option>
                <option class="mb-2" value="codeigniter">Codeigniter</option>
                <option class="mb-2" value="reactjs">React Js</option>
                <option class="mb-2" value="vue">Vue</option>
                <option class="mb-2" value="nodejs">Node Js</option>
                <option class="mb-2" value="phyton">Phyton</option>
                <option class="mb-2" value="javalang">Java</option>
                <option class="mb-2" value="go">Go</option>
                <option class="mb-2" value="cfamily">C Family</option>
                <option class="mb-2" value="flutter">Flutter</option>
                <option class="mb-2" value="reactnative">React Native</option>
                <option class="mb-2" value="javamobile">Java Mobile</option>
                <option class="mb-2" value="kotlin">Kotlin</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-4">
            <div className="flex flex-col gap-2 w-full lg:w-1/2">
              <label className='text-slate-500' htmlFor="traffic">Darimanakah kamu tau Course ini?</label>
              <select class="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 capitalize" name="traffic" id="traffic" title="Darimanakah kamu tau Course ini?"
                value={formData && formData.traffic}
                onChange={e => {
                  setFormData({
                    ...formData,
                    traffic: e.target.value
                  })
                }}>
                <option value="">--Pilih opsi--</option>
                <option value="instagram">Instagram</option>
                <option value="tiktok">Tiktok</option>
                <option value="website">Website</option>
                <option value="youtube">Youtube</option>
                <option value="facebook">Facebook</option>
                <option value="rekomendasi">Rekomendasi orang lain</option>
                <option value="iklan">Iklan</option>
                <option value="lainnya">Lainnya</option>
              </select>
            </div>
            <div className="flex flex-col gap-2 w-full lg:w-1/2">
              <label className='text-slate-500' htmlFor="instagram">Bio <span className='text-sm text-green-300'>*opsional</span></label>
              <textarea class="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 capitalize" type="text" cols="4" name="bio" id="bio" placeholder="Ceritakan tentangmu.."
                value={formData && formData.bio}
                onChange={e => {
                  setFormData({
                    ...formData,
                    bio: e.target.value
                  })
                }}>{formData && formData.bio}</textarea>
            </div>
          </div>
          <hr className="bg-b-slate-200 mt-4" />
          <div className="flex justify-end">
            <button type="submit" className="bg-green-500 hover:bg-green-600 ease-in duration-300 text-white rounded-md px-6 py-2 ">Submit</button>
          </div>
        </form>
      </div>
    </li>
  )
}

export default TabBio