import React, { useState } from 'react';

const ImageUploaderByUrl = ({loadingLink, setLoadingLink, onImageUpload }) => {
  const [imageUrl, setImageUrl] = useState('');
  const [isValidImage, setIsValidImage] = useState(false);
  const [previewSrc, setPreviewSrc] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleUrlChange = (e) => {
    setImageUrl(e.target.value);
    setPreviewSrc(null); // Reset preview when URL changes
    setErrorMessage('');  // Reset error message
    setIsValidImage(false); // Reset valid state
  };

  const validateImageUrl = async () => {
    try {
      // Fetch image and check Content-Type
      const response = await fetch(imageUrl, { method: 'HEAD' });
      const contentType = response.headers.get('Content-Type');

      if (response.ok && contentType.startsWith('image/')) {
        setPreviewSrc(imageUrl);  // Set preview image
        setIsValidImage(true);    // Mark image as valid
        setErrorMessage('');      // Clear any previous error message
      } else {
        throw new Error('Link bukan gambar atau tidak valid.');
      }
    } catch (error) {
      setErrorMessage(error.message || 'Gagal memuat gambar.');
      setIsValidImage(false);
    }
  };

  const handleSave = () => {
    setLoadingLink(1)
    if (isValidImage) {
      onImageUpload(imageUrl); // Pass the valid image URL to the parent component
    } else {
      setErrorMessage('URL gambar tidak valid. Tidak dapat menyimpan.');
      setLoadingLink(0)
    }
  };

  return (
      <div className="relative mt-4">
        <div className="flex flex-col gap-2 w-full pr-24 items-start">
          <h4 className="mb-4 font-semibold">Upload By Link</h4>
          <input className="w-full border border-slate-400 focus:border-sky-400 outline-none py-1.5 px-2 rounded-md" type="text"
                placeholder="Masukkan URL gambar"
                value={imageUrl}
                onChange={handleUrlChange} />
          <div className="flex gap-2 items-center justify-start mt-2">
            <button className="inline-block px-3 bg-green-400 whitespace-nowrap text-white py-1.5 rounded-md" type="button" onClick={validateImageUrl}><i className="fa-solid fa-check mr-1" />Check</button>

          </div>
            <div className="flex justify-center" id="status">
            {errorMessage && <p className="bg-red-400 text-white py-1 px-1.5 rounded-lg text-xs">{errorMessage}</p>}
            </div>
        </div>
        <div className="flex flex-col items-center gap-2 w-20 absolute top-0 right-0">
          <img id="previewImageLink" src={previewSrc} alt="image" className="w-20 h-20 border border-slate-300 rounded-full object-cover object-center" onError={() => setErrorMessage('Gagal memuat gambar.')}/>
          <p className="text-sm text-slate-400">Preview</p>
        </div>
        <div className={`${!isValidImage && 'hidden'} mt-10 border-t-2 border-t-slate-200 pt-4 flex justify-end`}>
          <button type="button" className={`${!isValidImage && 'hidden'} bg-red-400 py-2 px-4 rounded-md outline-none hover:bg-red-500 text-white`} onClick={handleSave}><i className="fa-solid fa-upload mr-2"/>
          {loadingLink == 0 ? 'Upload Foto' : 'Uploading...'}</button>
        </div>
      </div>
  );
};

export default ImageUploaderByUrl;
